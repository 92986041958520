/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { State } from "./types";

const initialState = {
  pending: false,
  transactionHash: "",
  error: "",
  showPopup: false,
  success: false,
  currentStepIndex: 0,
  letter: "",
  serum: "",
  word: "",
} as State;

const MergeSlice = createSlice({
  name: "merge",
  initialState,
  reducers: {
    clear(state) {
      state.word = "";
      state.serum = "";
      state.letter = "";
      state.currentStepIndex = 0;
    },
    setWord(state, action: PayloadAction<string>) {
      state.word = action.payload;
    },
    setSerum(state, action: PayloadAction<string>) {
      state.serum = action.payload;
    },
    setLetter(state, action: PayloadAction<string>) {
      state.letter = action.payload;
    },
    onSuccess(state) {
      state.showPopup = false;
      state.success = true;
    },
    setSuccess(state, action: PayloadAction<boolean>) {
      state.success = action.payload;
    },

    setShow(state, action: PayloadAction<boolean>) {
      state.showPopup = action.payload;
    },
    setError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    setHash(state, action: PayloadAction<string>) {
      state.transactionHash = action.payload;
    },
    setPending(state, action: PayloadAction<boolean>) {
      state.pending = action.payload;
    },
    setStep(state, action: PayloadAction<number>) {
      state.currentStepIndex = action.payload;
    },

    onError(state, action: PayloadAction<string>) {
      state.error = action.payload;
      // state.showMint = false
    },
  },
});

export const {
  clear,
  setWord,
  setSerum,
  setLetter,
  onSuccess,
  setSuccess,
  setShow,
  setError,
  setHash,
  setPending,
  setStep,
  onError,
} = MergeSlice.actions;

export default MergeSlice.reducer;
