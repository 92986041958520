import React from "react";
import images from "../../../../consts/images";
import BaseStep from "./BaseStep";





function MergeResultStep() {
  return (
    <BaseStep
    
      image = {images.home.brand1}
      header = {
        <>
        <h3>Confirm Result</h3>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit risus vitae. Nisi vel <strong>MERGE</strong> faucibus vel et,</p>
        </>
      }
    />
  );
}

export default MergeResultStep;
