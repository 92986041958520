import React from "react";
import FadingLine from "../../../components/FadingLine";
import ScreenGrid from "../../../components/Screen/ScreenGrid";
import images from "../../../consts/images";
import SectionHeader from "../SectionHeader";
import data from "./data";
const Fade = require("react-reveal/Fade");
const Zoom = require("react-reveal/Zoom");

function Chart() {
  return (
    <ScreenGrid>
      <div className="chart">
        <SectionHeader
          title="Community Wallet"
          subtitle="
          The Symbols will allocate a substantial portion of our mint to support ongoing initiatives for years to come "
        />
        <div className="chart-content">
          <Zoom clear duration={500}>
            <img
              src={images.home.chart.chart}
              alt="pie"
              className="chart-image"
            />
          </Zoom>
          {data.map((item, index) => {
            const id = index + 1;
            return (
              <span key={item.id}>
                <Fade delay={400 * index} duration={500}>
                  <div className={`chart-dot chart-dot-${id}`}></div>
                  <h4 className={`chart-percent chart-percent-${id}`}>
                    {item.percent}
                  </h4>
                  <h5 className={`chart-text chart-text-${id}`}>{item.text}</h5>
                </Fade>
                <div className={`chart-line chart-line-${id}`}>
                  <FadingLine opacity={0.1} />
                </div>
              </span>
            );
          })}
        </div>
      </div>
    </ScreenGrid>
  );
}

export default Chart;
