export enum Network {
  ETHEREUM = 1,
  ROPSTEN = 3,
  RINKEBY = 4,
}

const networks: { [key: number]: { name: string } } = {
  [Network.ETHEREUM]: {
    name: "Ethereum",
  },
  [Network.RINKEBY]: {
    name: "Rinkeby",
  },
};

export { networks };
