import telegram from "../assets/images/socials/telegram.svg";
import discord from "../assets/images/socials/discord.svg";
import twitter from "../assets/images/socials/twitter.svg";
import openSea from "../assets/images/socials/opensea.svg";

import telegramWhite from "../assets/images/socials/telegram-white.svg";
import discordWhite from "../assets/images/socials/discord-white.svg";
import twitterWhite from "../assets/images/socials/twitter-white.svg";
import openSeaWhite from "../assets/images/socials/opensea-white.svg";

import logo from "../assets/images/navbar/logo.svg";
import hero from "../assets/images/home/hero.png";
import polygon from "../assets/images/home/polygon.svg";
import square from "../assets/images/home/square.png";
import minting1 from "../assets/images/home/minting1.png";
import minting2 from "../assets/images/home/minting2.png";
import minting3 from "../assets/images/home/minting3.png";
import minting4 from "../assets/images/home/minting4.png";
import mintingPreview from "../assets/images/home/minting-preview.jpg";
import mergin from "../assets/images/home/mergin.png";

import coloredCircle from "../assets/images/home/shapes/circle.svg";
import coloredTriangle from "../assets/images/home/shapes/triangle.svg";
import coloredSquare from "../assets/images/home/shapes/square.svg";

import roadmapShape from "../assets/images/home/roadmap/shape.svg";

import brand1 from "../assets/images/home/brands/brand1.png";
import mintHero from "../assets/images/mint/hero.png";
import arrowBottom from "../assets/images/common/arrow-bottom.svg";

import brandApple from "../assets/images/brands/brand1.png";
import contact from "../assets/images/common/contact.svg";

import footerLogo from "../assets/images/common/footer-logo.svg";
import close from "../assets/images/common/close.svg";
import shape1 from "../assets/images/common/shape1.svg";
import shape2 from "../assets/images/common/shape2.svg";
import shape3 from "../assets/images/common/shape3.svg";
import shape4 from "../assets/images/common/shape4.svg";
import shape5 from "../assets/images/common/shape5.svg";
import bg1 from "../assets/images/home/bg-1.svg";
import arrowsBottom from "../assets/images/common/arrows-bottom.svg";
import whiteArrow from "../assets/images/common/white-arrow-left.svg";
import doubleSquare from "../assets/images/common/double-square.svg";
import squareCircle from "../assets/images/common/square-circle.svg";
import chart from "../assets/images/home/chart/chart.svg";
import smallTransaparentSquare from "../assets/images/home/mods/square.svg";
import smallTransaparentCircle from "../assets/images/home/mods/circle.svg";
import smallTransaparentTriangle from "../assets/images/home/mods/arrow.svg";

import transparentCircle from "../assets/images/home/shapes/transparent-circle.svg";
import transparentSquare from "../assets/images/home/shapes/transparent-square.svg";
import quotes from "../assets/images/home/founders/quotes.svg";
import q1 from "../assets/images/home/roadmap/q1.svg";
import q2 from "../assets/images/home/roadmap/q2.svg";
import q3 from "../assets/images/home/roadmap/q3.svg";
import q4 from "../assets/images/home/roadmap/q4.svg";

const images = {
  brands: {
    brandApple,
  },
  common: {
    arrowBottom,
    contact,
    footerLogo,
    close,
    shape1,
    shape2,
    shape3,
    shape4,
    shape5,
    arrowsBottom,
    whiteArrow,
    doubleSquare,
    squareCircle,
    smallTransaparentSquare,
    smallTransaparentCircle,
    smallTransaparentTriangle,
    quotes,
  },
  socials: {
    telegram,
    discord,
    twitter,
    openSea,
    telegramWhite,
    discordWhite,
    twitterWhite,
    openSeaWhite,
  },
  navbar: {
    logo,
  },
  home: {
    brand1,
    hero,
    polygon,
    square,
    minting1,
    minting2,
    minting3,
    minting4,
    mintingPreview,
    mergin,
    coloredCircle,
    coloredTriangle,
    coloredSquare,
    roadmapShape,
    transparentCircle,
    transparentSquare,
    bg1,
    q1,
    q2,
    q3,
    q4,
    chart: {
      chart,
    },
  },
  mint: {
    hero: mintHero,
  },
};

export default images;
