import React from "react";
import { MemberInterface } from "./types";
const Fade = require("react-reveal/Fade");

interface Props {
  member: MemberInterface;
  index: number;
  text: string;
}

function Member({ member, text }: Props) {
  const { shape, avatar, name, position, url, twitterName, twitterUrl, instagramUrl, instagramName } =
    member;

  return (
    <Fade>
      <section className="member">
        <div className="member-images">
          <img className="member-shape" src={shape} alt="shape" />
          <figure>
            <img className="member-details-avatar" src={avatar} alt="member" />
          </figure>
        </div>
        <div className="member-details">
          <h4>{name}</h4>
          <h5>{position}</h5>
          <p>{text}</p>
          {twitterUrl && (
            <a rel="noreferrer" href={twitterUrl} target="_blank">
              {twitterName}
            </a>
          )}
          {url && (
            <a rel="noreferrer" href={url} target="_blank">
              {url}
            </a>
          )}
          {instagramUrl && (
           <div style={{marginTop:5}}>
              <a rel="noreferrer" href={instagramUrl} target="_blank">
             {instagramName}
            </a>
           </div>
          )}
        </div>
      </section>
    </Fade>
  );
}

export default Member;
