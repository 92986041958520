const env = {
  infura: process.env.REACT_APP_INFURA_ID,
  tokenContract: process.env.REACT_APP_TOKEN_CONTARCT,
  network: process.env.REACT_APP_SUPPORTED_NETWORK,
  etherscanUrl: process.env.REACT_APP_ETHERSCAN_URL,
  rpc: process.env.REACT_APP_RPC,
  apiEndpoint: process.env.REACT_APP_API_ENDPOINT,
  moonpayKey: process.env.REACT_APP_MOONPAY_KEY
};

export default env;
