import React from "react";
import images from "../../consts/images";
import ScreenGrid from "../Screen/ScreenGrid";
import Socials from "../Socials";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import { ROUTES } from "consts";


function Footer() {
  return (
    <footer className="footer">
      <ScreenGrid id='footer-flex'>
        <HashLink to='/#' smooth>
        <img src={images.common.footerLogo} alt="logo" className="footer-logo" />
        </HashLink>
        <div className="footer-license">
        <p className="footer-text">L3TR Labs © 2022</p> | <Link to={ROUTES.license}>Symbols license</Link>
        </div>
        <Socials light />
      </ScreenGrid>
    </footer>
  );
}

export default Footer;
