import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import { NAVIGATION } from '../../consts';
import { NavigationInterface, NAVIGATION_TYPES } from '../../types';

interface Props {
    route: NavigationInterface;
}

const LinkComponent = ({ route }: Props) => {
    const { path, type, title } = route;
    if (type === NAVIGATION_TYPES.HASH) {
        return <HashLink smooth to={path}>{title}</HashLink>;
    }
    return <Link to={path}>{title}</Link>;
};

function Navigation({onClick}: {onClick?: () => void}) {
    return (
        <ul className="navbar-navigation">
            {NAVIGATION.map((route: NavigationInterface) => {
                return (
                    <li key={route.key} className="navbar-navigation-link" onClick = {onClick}> 
                        <LinkComponent route={route} />
                    </li>
                );
            })}
        </ul>
    );
}

export default Navigation;
