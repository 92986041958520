import React, { ReactNode } from "react";
import images from "../../../consts/images";
import Screen from "../index";
import Lines from "./Lines";

interface Props {
  children: ReactNode;
  id?: string;
}

function TransactionScreen({ children, id = "" }: Props) {
  return (
    <Screen id={id} className="transaction-screen">
        <div className='transaction-screen-figures'>
        <Lines />
      <img
        src={images.common.shape1}
        alt="shape"
        id="transaction-screen-shape"
      />
        </div>
      {children}
    </Screen>
  );
}

export default TransactionScreen;
