export enum NAVIGATION_TYPES {
  LINK = "LINK",
  HASH = "HASH",
}

export interface NavigationInterface {
  title: string;
  path: string;
  key: string;
  type: NAVIGATION_TYPES;
}

export enum InputValidations {
  EMAIL = "EMAIL",
  TEXT = "TEXT",
}

export interface BaseInput {
  name: string;
  placeholder: string;
  validation?: InputValidations;
  isTextArea?: boolean;
  id: string;
}



export interface InputErrors {
  [key: string]: string;
}
