import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

interface Props {
    size?: number;
    color?: string;
}

function Spinner({ size = 30, color = 'white' }: Props) {
    return <CircularProgress className='spinner' style={{ color }} size={size} />;
}

export default Spinner;
