import React, { useMemo } from "react";
import { getSocials } from "./data";
interface Props {
  light?: boolean;
}

function Socials({ light }: Props) {
    const socials = useMemo(() => getSocials(light), [light])
  return (
    <div className="socials">
      {socials && socials.map((social) => {
        return (
          <a
            target="_blank"
            href={social.url}
            rel="noopener noreferrer"
            className="socials-item"
            key={social.id}
          >
            <img src={social.image} style={{width: '24px'}} alt="social" />
          </a>
        );
      })}
    </div>
  );
}

export default Socials;
