import { Fade } from "@mui/material";
import React from "react";
// import Invitation from 'assets/images/invitation.jpg'

function Iframe() {
  return (
    <Fade in>
      <div className="nyc-gif">
        <p className="nyc-gif-text">
          Great success, we've noted your wallet address please look for an
          airdrop annoucement after our mint for your reward BIG [✨]
        </p>
        {/*  <p className="nyc-gif-text">*/}
        {/*    212 bowery #PH*/}
        {/*</p>*/}
        {/*<img alt="Invitation" src={Invitation} />*/}
      </div>
    </Fade>
  );
}

export default Iframe;
