import React from "react";
import ScreenGrid from "../../../components/Screen/ScreenGrid";
import SectionHeader from "../SectionHeader";
import data from "./data";
import Video from "../../../components/Video";
import videoSrc from "../../../assets/videos/silver.mp4";
import videoPoster from "../../../assets/images/home/metaverse/poster.png";
const Fade = require("react-reveal/Fade");

function Metaverse() {
  return (
    <ScreenGrid>
      <div className="home-metaverse">
        <SectionHeader
          title="VR symbols metaverse pack"
          subtitle="AR/ VR and IRL Symbols font packs are available for partnerships and collaborations"
        />
        <div className="home-metaverse-list">
          {data.map((item, index) => {
            const delayIndex = data.length - index;
            return (
              <Fade delay={delayIndex * 100} key={item.id}>
                <Video
                  className="home-metaverse-list-item"
                  video={item.video}
                  poster={item.poster}
                />
              </Fade>
            );
          })}
        </div>
        <Video id="big-video" video={videoSrc} poster={videoPoster} />
      </div>
    </ScreenGrid>
  );
}

export default Metaverse;
