import { v4 as uuidv4 } from 'uuid';

const data = [
    {
        text: 'ongoing development',
        percent: '40%',
        id: uuidv4()
    },
    {
        text: 'marketing & events',
        percent: '30%',
        id: uuidv4()
    },
    {
        text: 'community management',
        percent: '20%',
        id: uuidv4()
    },
    {
        text: 'charitable foundation',
        percent: '10%',
        id: uuidv4()
    }
]

export default data
