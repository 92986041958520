import React, { useRef, useState } from "react";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";

interface Props {
  video: string;
  poster?: string;
  className?: string;
  id?: string;
  muted?: boolean;
  autoPlay?: boolean;
  loop?: boolean;
  muteControls?: boolean;
}

interface MuteButtonProps {
  isMuted: boolean;
  mute: () => void;
  unmute: () => void;
}

const MuteButton = ({ isMuted, mute, unmute }: MuteButtonProps) => {
  return isMuted ? (
    <button className="video-volume" onClick={unmute}>
      <VolumeUpIcon style={{color:'white'}} />
    </button>
  ) : (
    <button className="video-volume" onClick={mute}>
      <VolumeOffIcon style={{color:'white'}}  />
    </button>
  );
};

function Video({
  video,
  poster,
  className = "",
  id = "",
  muted = true,
  autoPlay = true,
  loop = true,
  muteControls = false,
}: Props) {
  const refVideo = useRef<any>(null);
  const [isMuted, setIsMuted] = useState(true);

  const unmute = () => {
    if (!refVideo.current) {
      return;
    }
    refVideo.current.muted = false;
    setIsMuted(false);
  };

  const mute = () => {
    if (!refVideo.current) {
      return;
    }
    refVideo.current.muted = true;
    setIsMuted(true);
  };

  return (
    <div className={`video ${className}`} id={id}>
      <video
        ref={refVideo}
        autoPlay={autoPlay}
        poster={poster}
        loop={loop}
        playsInline
        muted={muted}
      >
        <source src={video} />
      </video>
      {muteControls && (
        <MuteButton isMuted={isMuted} unmute={unmute} mute={mute} />
      )}
    </div>
  );
}

export default Video;
