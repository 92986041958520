import useWeb3 from "hooks/useWeb3";
import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import useMintStore from "store/hooks/useMintStore";
import { setToggleConfigLoading } from ".";
import { mintAllowlistAction, mintPublicAction, validateWalletBalanceAction } from "./actions";

export const useMintActions = (): {
  mint: (amount: number) => void;
  validateWalletBalance: (amount: number) => void;
  toggleConfigLoading: (val: boolean) => void
} => {
  const { account, library } = useWeb3();
  const { isAllowlistSaleOpen, isPublicSaleOpen } = useMintStore();
  const dispatch = useDispatch();
  const mint = useCallback(
    async (amount: number) => {
      if (isAllowlistSaleOpen) {
        dispatch(mintAllowlistAction({ amount, account }));
      } else if (isPublicSaleOpen) {
        dispatch(mintPublicAction({ amount, account }));
      }
    },
    [account, dispatch, isPublicSaleOpen, isAllowlistSaleOpen]
  );


    const validateWalletBalance = useCallback(
      (amount: number) => {
        dispatch(validateWalletBalanceAction({account, library, amount}))
      },
      [dispatch, account, library],
    )

    const toggleConfigLoading = useCallback(
        (value: boolean) => {
          dispatch(setToggleConfigLoading(value))
        },
        [dispatch],
      )


  return { mint, validateWalletBalance, toggleConfigLoading };
};

export const useMintDerivedData = () : {
    isMintedAll: boolean;
    mintsAllowed: number;
}=> {
  const { accountMints, maxMintAmount } = useMintStore();

  const isMintedAll = useMemo(() => {
    return accountMints > 0 && accountMints >= maxMintAmount;
  }, [accountMints, maxMintAmount]);

  const mintsAllowed = useMemo(() => {
    return maxMintAmount - accountMints;
  }, [accountMints, maxMintAmount]);

    return  {isMintedAll, mintsAllowed}
};
