import React from "react";
import Screen from 'components/Screen'
function License() {
  return (
    <Screen id="license">
      <div className="license-content">
      <h1>L3TR LABS NFT License</h1>
      <section>
        <h5>1.Definitions.</h5>
        <p>
          “Art” means any art, design, drawing, or other pictorial or graphic
          work of authorship that may be associated with an NFT that you Own.
          "NFT" means any blockchain-tracked, non-fungible token, including (but
          not limited to) those conforming to the ERC-721 or ERC-721A standards,
          associated with The Symbols project. “Own” means, with respect to an
          NFT, an NFT that you have purchased or otherwise rightfully and
          legally acquired from a legitimate source, where proof of such
          acquisition and ownership is recorded on the relevant blockchain.
          “Ownership” means the legal status of a person or entity that Owns a
          Purchased NFT. “Purchased NFT” means an NFT that you Own. “Third Party
          IP” means any third-party patent rights (including, without
          limitation, patent applications, provisional patents, and
          disclosures), copyrights, trade secrets, trademarks, know-how or any
          other intellectual property rights recognized in any country or
          jurisdiction in the world.
        </p>
      </section>
      <section>
        <h5>2.Ownership.</h5>
        <p>
          The Ownership rights a holder of a Purchased NFT issued by L3TR LABS
          INC (“L3TR LABS”) has are limited to the NFT itself, except as
          otherwise described herein. You acknowledge and agree that L3TR LABS
          (or, as applicable, its licensors) owns all worldwide legal right,
          title and interest in and to the Art, and all intellectual property
          rights therein. The rights that you have in and to the Art are limited
          to those described in this License. L3TR LABS reserves all rights in
          and to the Art not expressly granted to you in this License.
        </p>
      </section>
      <section>
        <h5>3.License.</h5>
        <p>
          a. General Use. Subject to and conditioned upon your continued
          compliance with the terms of this License, L3TR LABS grants the
          holders of a Purchased NFT a worldwide, non-exclusive, royalty-free
          license, with no rights to sub-license or assign the rights granted
          under this paragraph, to use, copy, and display the Art associated
          with such Purchased NFTs, solely for the following purposes: (i) for
          your own personal, non-commercial use (ii) as part of a marketplace
          that permits the purchase and sale of your NFTs, provided that the
          marketplace
        </p>
        <p>
          b. cryptographically verifies each NFT owner’s rights to display the
          Art associated with their Purchased NFTs to ensure that only the
          actual owner can display the Art; or (iii) as part of a third party
          website or application that permits the inclusion, involvement, or
          participation of your NFTs, provided that the website/application
          cryptographically verifies each NFT owner’s rights to display the Art
          associated with their Purchased NFTs to ensure that only the actual
          owner can display the Art, and provided that the Art is no longer
          visible once the owner of the Purchased NFT leaves the
          website/application. For the avoidance of doubt, nothing herein should
          be construed to grant any owner of a Purchased NFT the right to create
          derivative works utilizing the Art associated with such Purchased NFT,
          except as provided in section 4 below. All rights granted under this
          paragraph terminate immediately and absolutely once a licensee ceases
          to Own the relevant Purchased NFT for any reason.Commercial Use. The
          holder of a Purchased NFT shall not be granted any license to utilize
          the Art associated with the Purchased NFT for commercial purposes. For
          the sake of clarity, nothing in this Section 3.b will be deemed to
          restrict you from (i) owning or operating a marketplace that permits
          the use and sale of NFTs generally, provided that the marketplace
          cryptographically verifies each NFT owner’s rights to display the Art
          associated with their Purchased NFTs to ensure that only the actual
          owner can display the Art; (ii) owning or operating a third party
          website or application that permits the inclusion, involvement, or
          participation of NFTs generally, provided that the third party website
          or application cryptographically verifies each NFT owner’s rights to
          display the Art associated with their Purchased NFTs to ensure that
          only the actual owner can display the Art, and provided that the Art
          is no longer visible once the owner of the Purchased NFT leaves the
          website/application; or (iii) earning revenue from any of the
          foregoing.
        </p>
        <p>
          c. Usage in The Symbols Applications. Subject to and conditioned upon
          your continued compliance with the terms of this License, L3TR LABS
          grants the holders of a Purchased NFT a worldwide, non-exclusive,
          royalty-free license, with no rights to sub-license or assign the
          rights granted under this paragraph, to use, copy, and display the Art
          associated with all NFTs within messaging applications released by
          L3TR LABS.
        </p>
      </section>
      <section>
        <h5>4. Restrictions.</h5>
        <p>
          You agree that you may not, nor permit any third party to do or
          attempt to do any of the foregoing without L3TR LABS’s express prior
          written consent in each case: (i) modify the Art associated with your
          Purchased NFT in any way, including, without limitation, the shapes,
          designs, drawings, attributes, or color schemes, or create derivative
          works of any kind; (ii) use the Art associated with your Purchased
          NFTs to advertise, market, or sell any third party product or service;
          (iii) use the Art associated with your Purchased NFTs in connection
          with images, videos, or other forms of media that depict hatred,
          intolerance, violence, cruelty, or anything else that could reasonably
          be found to constitute hate speech or otherwise infringe upon the
          rights of others; (iv) use the Art associated with your Purchased NFTs
          in movies, videos, or any other forms of media, except to the limited
          extent that such use is expressly permitted in Section 3(b) above or
          solely for your own personal, non-commercial use; (v) sell, distribute
          for commercial gain (including, without limitation, giving away in the
          hopes of eventual commercial gain), or otherwise commercialize
          merchandise that includes, contains, or consists of the Art associated
          with your Purchased NFTs, except as expressly permitted in Section
          3(b) above; (vi) attempt to trademark, copyright, or otherwise acquire
          intellectual property rights in or to the Art associated with your
          Purchased NFTs; or (vii) otherwise utilize the Art associated with
          your Purchased NFTs for your or any third party’s commercial benefit,.
          To the extent that Art associated with your Purchased NFTs contains
          Third Party IP (e.g., licensed intellectual property from a celebrity,
          athlete, or other public figure), you understand and agree as follows:
          (w) that you will not have the right to use such Third Party IP in any
          way except as incorporated in the Art, and subject to the License and
          restrictions contained herein; (x) that, depending on the nature of
          the license granted from the owner of the Third Party IP, L3TR LABS
          may need to pass through additional restrictions on your ability to
          use the Art; and (y) to the extent that L3TR LABS informs you of such
          additional restrictions in writing (email or publication on the
          website is permissible), you will be responsible for complying with
          all such restrictions from the date that you receive the notice, and
          that failure to do so will be deemed a breach of this License. The
          restriction in this Section 4 will survive the expiration or
          termination of this License.
        </p>
      </section>
      <section>
        <h5>5. Terms of License.</h5>
        <p>
          If an owner of a Purchased NFT generates any revenue or income through
          the utilization of a Purchased NFT, other than as expressly permitted
          by the terms of this License, such owner will be in breach of this
          License, and must send an email to L3TR LABS at david@thesymbols.io
          within fifteen (15) days, with the phrase “NFT License - Commercial
          Use” in the subject line, requesting a discussion with L3TR LABS
          regarding entering into a broader license agreement or obtaining an
          exemption (which may be granted or withheld in L3TR LABS’s sole and
          absolute discretion). If an owner of a Purchased NFT exceeds the scope
          of the license grant in Section 3 in any way without entering into a
          broader license agreement with or obtaining an exemption from L3TR
          LABS, such owner acknowledges and agrees that: (i) the owner is in
          breach of this License; (ii) in addition to any remedies that may be
          available to L3TR LABS at law or in equity, L3TR LABS may immediately
          terminate this License with respect to that specific licensee, without
          the requirement of notice; and (iii) the relevant owner will be
          responsible to reimburse L3TR LABS for any costs and expenses incurred
          by L3TR LABS during the course of enforcing the terms of this License
          against the owner.
        </p>
      </section>
      </div>
    </Screen>
  );
}

export default License;
