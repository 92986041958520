import { ReactNode } from "react";
import { VariantType, useSnackbar, SnackbarOrigin } from "notistack";
import { IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import CustomButton from "components/CustomButton";
import { styled } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";

const StyledButton = styled(Box)({
  "& .main-button": {
    height: 30,
    paddingLeft: 10,
    paddingRight: 10,
    "& p": {
      fontSize: 14,
    },
  },
});

interface Params {
  message: ReactNode | string;
  variant: VariantType;
  onClose?: () => void;
  autoHideDuration?: number;
  anchorOrigin?: SnackbarOrigin;
  disableClose?: boolean;
  action?: () => void;
  actionText?: string;
  closeAction?: boolean;
}

function useNotification() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const showNotification = ({
    message,
    variant,
    onClose,
    autoHideDuration,
    anchorOrigin,
    disableClose,
    action,
    actionText,
    closeAction,
  }: Params) => {
    const key =   enqueueSnackbar(message, {
      anchorOrigin: anchorOrigin,
      variant,
      autoHideDuration: autoHideDuration || null,
      onClose,
      onClick: () => !disableClose && closeSnackbar(key),
      action: () =>
        closeAction ? (
          <IconButton>
            <CloseIcon style={{ width: 20, height: 20, color: "white" }} />
          </IconButton>
        ) : (
          action && (
            <StyledButton>
              <CustomButton onClick={action}>
                <Typography>{actionText}</Typography>
              </CustomButton>
            </StyledButton>
          )
        ),
    });
    return key
  };

  return {
    showNotification,
    hideNotification: (key: any) => closeSnackbar(key),
  };
}

export default useNotification;
