import * as React from "react";
import Dialog from "@mui/material/Dialog";
import CloseButton from "components/CloseButton";

export interface Props {
  open: boolean;
  children: React.ReactNode;
  onClose: () => void;
  transparent?: boolean;
  className?: string;
  transparentPaper?: boolean;
}

const Popup = ({
  children,
  open,
  onClose,
  transparent,
  className = "",
}: Props) => {
  return (
    <Dialog
      className={`popup ${className}`}
      onClose={onClose}
      open={open}
      PaperProps={{
        style :{
          width:'100%',
          background: "transparent",
          height: '100%',
          display:'flex',
          alignItems:'center',
          justifyContent:'center',
          maxHeight:'unset',
          boxShadow:'unset'
        }
      }}
      BackdropProps={{
        style: !transparent
          ? {}
          : {
              backgroundColor: "transparent",
              boxShadow: "none",
            },
      }}
    >
       <CloseButton onClick={onClose} />
      <div className="popup-content">
      {children}
      </div>
    </Dialog>
  );
};

export default Popup;
