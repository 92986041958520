import { configureStore } from '@reduxjs/toolkit';
import mintReducer from './reducers/mint';
import MergeReducer from './reducers/merge/reducer'
import mainReducer from './reducers/main';
import walletReducer from './reducers/wallet'

const store = configureStore({
    reducer: {
        merge: MergeReducer,
        mint: mintReducer,
        wallet: walletReducer,
        main: mainReducer
    }
});
export type RootState = ReturnType<typeof store.getState>;

export default store;
