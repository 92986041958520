import { Fade } from "@mui/material";
import React, { ReactNode, useEffect, CSSProperties } from "react";


interface Props {
  children: ReactNode;
  id?: string;
  style?: CSSProperties;
  className?: string;
}

function Screen({ children, id = "", style = {}, className }: Props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  

  return (
    <Fade in>
      <div style={style} className={`screen ${className}`} id={id}>
      {children}

    </div>
    </Fade>
  );
}

export default Screen;
