import { toChecksumAddress } from "utils/web3";

export const validateUser = async (address?: string | null) => {
    
  if (!address) {
    return false;
  }

  return fetch(
    `https://api.allorigins.win/get?url=https://api.premint.xyz/v1/cfe34bf5af11680b53ff4c0cd27875b1005650e73a717f67fad91855a5a40115?${new Date().getTime()}`
  )
    .then((response) => {
      if (response.ok) return response.json();
      throw new Error("Network response was not ok.");
    })
    .then((data) => {
      const res = JSON.parse(data.contents).data;
      return res.find(
        (m: any) =>
          toChecksumAddress(m.wallet) ===
          toChecksumAddress(address!!)
      );
    });
};
