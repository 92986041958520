import CustomButton from "components/CustomButton";
import BasicTooltip from "components/CustomTooltip";
import ActionButton from "components/transactions/ActionButton";
import useWeb3 from "hooks/useWeb3";
import React, {useMemo} from "react";
import {useDispatch} from "react-redux";
import useMintStore from "store/hooks/useMintStore";
import {setShowModal} from "store/reducers/mint";
import { toggleConnectModal } from "store/reducers/wallet";
import TokenContract from "../../contracts/token";

const getAccess = (
    isPublicSaleOpen: boolean,
    isAllowlistSaleOpen: boolean,
    account?: string | null
) => {
    if (!account) {
        return {
            disabled: true,
        };
    }
    if (isPublicSaleOpen) {
        return {
            disabled: false,
        };
    }
    if (isAllowlistSaleOpen) {
        const isWhiteListed = TokenContract.getWhitelistedAccount(account);
        if (isWhiteListed) {
            return {
                disabled: false,
            };
        }
        return {
            disabled: true,
            message: "You are not whitelisted",
        };
    } else {
        return {
            disabled: true,
            message: "Minting is over",
        };
    }
};

function MintAction() {
    const {isPublicSaleOpen, isAllowlistSaleOpen, configLoading} = useMintStore();
    const {account} = useWeb3();
    const dispatch = useDispatch();

    const onMint = () => {
        dispatch(setShowModal(true));
    };


    const access = useMemo(
        () => getAccess(isPublicSaleOpen, isAllowlistSaleOpen, account),
        [isPublicSaleOpen, isAllowlistSaleOpen, account]
    );

    return (
        <div className="mint-action">
            {!account? (
                <CustomButton  onClick={() => dispatch(toggleConnectModal(true))}>
                    Mint
                </CustomButton>
            ) : account && access?.disabled ? (
                <BasicTooltip text={access.message}>
                    <CustomButton disabled onClick={() => {
                    }}>
                        Mint
                    </CustomButton>
                </BasicTooltip>
            ) : (
                <ActionButton
                    actionPending={configLoading}
                    actionText="Mint"
                    onAction={onMint}
                />
            )}
        </div>
    );
}

export default MintAction;
