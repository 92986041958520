import { getApps, initializeApp } from "firebase/app";
import { doc, getDoc, getFirestore, setDoc } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  useCollectionData,
  useCollectionDataOnce,
} from "react-firebase-hooks/firestore";

if (!getApps().length) {
  initializeApp({
    apiKey: "AIzaSyD7GIh-uHCUtsXSerEBrcx0GvBp49AqY-A",
    authDomain: "the-symbols.firebaseapp.com",
    projectId: "the-symbols",
    storageBucket: "the-symbols.appspot.com",
    messagingSenderId: "127846143946",
    appId: "1:127846143946:web:165eadabbc01e0418c6133",
  });
}
const db = getFirestore();

const sendWalletAddress = async (address?: string | null) => {
  if (!address) {
    return;
  }
  const docRef = doc(db, `wallets/${address}`);

  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return;
  } else {
    return setDoc(docRef, { address });
  }
};

const setMinterDetails = async (address: string, email: string) => {
  const docRef = doc(db, `minters/${address}`);

  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return;
  } else {
    return setDoc(docRef, { address, email });
  }
};

export {
  useAuthState,
  useCollectionData,
  useCollectionDataOnce,
  sendWalletAddress,
  setMinterDetails,
};
