import React from "react";
import ScreenGrid from "../../../components/Screen/ScreenGrid";
import SectionHeader from "../SectionHeader";

function Press() {
  return (
    <ScreenGrid>
      <div className="home-pr">
        <SectionHeader
          title="PR, Media and Partnership Inquires"
          subtitle={<>Email: <a href="mailto:Thesymbols@thedooronline.com">Thesymbols@thedooronline.com</a></>}
        />
      </div>
    </ScreenGrid>
  );
}

export default Press;
