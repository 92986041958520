import React, { useState } from "react";
import Spinner from "../Spinner";

interface Props {
  src?: string;
}

function LoadingImage({ src }: Props) {
  const [isLoaded, setisLoaded] = useState(false);
  return (
    <div className={`loading-image ${isLoaded && 'loading-image-loaded'}`}>
     {!isLoaded &&  <Spinner size={80} color="#4743FF" />}
      {src && (
        <img
          style={{ opacity: isLoaded ? 1 : 0 }}
          src={src}
          onLoad={() => setisLoaded(true)}
          alt=""
        />
      )}
    </div>
  );
}

export default LoadingImage;
