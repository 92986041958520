import React from "react";
import Indicator from "../../../components/Indicator";
import ScreenGrid from "../../../components/Screen/ScreenGrid";
import SectionHeader from "../SectionHeader";
import { founders } from "./data";
import Founder from "./Founder";
import Symbols from "./Symbols";

function Founders() {
  return (
    <ScreenGrid>
      <div className="founders">
        <Indicator id="founders" />
        <SectionHeader
          title="The Founders Collection"
          subtitle="The Founder collection consists of 26 one of one gold ERC721 NFTs. Each Symbol represents the first unique letters minted in the Symbols alphabet and comes with a physical artwork made on italian linen. Holders of these NFTs are entitled to a royalty distribution on all sales and resales of their held letter in the Genesis mint."
        />

        <div className="founders-list">
          {founders.map((founder, index: number) => {
            return <Founder founder={founder} key={founder.id} />;
          })}
        </div>
        <Symbols />
      </div>
    </ScreenGrid>
  );
}

export default Founders;
