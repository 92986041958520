import React, {ReactNode} from 'react';
const Fade = require("react-reveal/Fade");

interface Props {
    title: string;
    subtitle: string | ReactNode;
}
function SectionHeader({ title, subtitle }: Props) {
    return (
        <Fade>
            <div className="home-section-header">
            <h2>{title}</h2>
            <h5>{subtitle}</h5>
        </div>
        </Fade>
    );
}

export default SectionHeader;
