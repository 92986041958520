import ActionButton from "components/transactions/ActionButton";
import React from "react";
import FadingLine from "../../../components/FadingLine";
import ScreenGrid from "../../../components/Screen/ScreenGrid";
import images from "../../../consts/images";
const Fade = require("react-reveal/Fade");

function Mergin() {
  return (
    <div className="home-mergin">
      <Fade right>
        <img src={images.common.shape3} alt="shape" id="mergin-shape" />
      </Fade>
      <ScreenGrid>
        <div className="home-mergin-flex">
          <Fade bottom>
            <div className="home-mergin-left flex-container-content">
              <h2>
                Airdrop and Words <br /> mint coming soon
              </h2>
              <p className="flex-container-content-text">
                Airdrop coming... Each Symbol held will provide for one randomly selected ⬜⬜⬜⬜⬜. Some ⬜⬜⬜⬜⬜ are more special than others.

              </p>

              <ActionButton
                actionText="Coming soon"
                onAction={() => {}}
                actionPending={false}
                comingSoon
              />
              <FadingLine opacity={0.1} id="home-mergin-line" />
            </div>
          </Fade>
          {/*<Fade right>*/}
          {/*  <img*/}
          {/*    className="home-mergin-right"*/}
          {/*    src={images.home.mergin}*/}
          {/*    alt="spray"*/}
          {/*  />*/}
          {/*</Fade>*/}
        </div>
      </ScreenGrid>
    </div>
  );
}

export default Mergin;
