import React, { useState } from "react";
import CloseButton from "../CloseButton";
import Socials from "../Socials";
import Connect from "./Connect";
import Navigation from "./Navigation";
import MenuIcon from "@mui/icons-material/Menu";

function Mobile() {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <>
      <button className="mobile-toggle" onClick={() => setShowMenu(!showMenu)}>
        <MenuIcon style={{ width: 40, height: 40, color:'white' }} />
      </button>
      <div className={`mobile-menu ${showMenu && "mobile-menu-show"}`}>
        <CloseButton onClick={() => setShowMenu(false)} />
        <Navigation onClick={() => setShowMenu(false)} />
        <Connect />
        <Socials />
      </div>
    </>
  );
}

export default Mobile;
