import useWeb3 from "hooks/useWeb3";
import React  from "react";
import { useDispatch } from "react-redux";
import { toggleConnectModal } from "store/reducers/wallet";
import { changeWalletNetwork } from "utils/web3";
import CustomButton from "../../CustomButton";

interface Props {
  onAction: () => void;
  actionPending: boolean;
  actionText: string;
  disabled?: boolean;
  allowClickWhileLoading?: boolean;
  comingSoon?: boolean;
}

function ActionButton({
  onAction,
  actionPending,
  actionText,
  disabled,
  allowClickWhileLoading,
  comingSoon,
}: Props) {
  const { account, isValidNetwork, library } = useWeb3();
  const dispatch = useDispatch();

  const onChangeNetwork = async () => {
    try {
      await changeWalletNetwork(library);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {comingSoon ? (
        <CustomButton disabled onClick={() => {}}>
          {actionText}
        </CustomButton>
      ) : !account ? (
        <CustomButton onClick={() => dispatch(toggleConnectModal(true))}>
          Connect
        </CustomButton>
      ) : !isValidNetwork ? (
        <CustomButton onClick={onChangeNetwork}>Change network</CustomButton>
      ) : (
        <CustomButton
          disabled={disabled}
          allowClickWhileLoading={allowClickWhileLoading}
          onClick={!disabled ? onAction : () => {}}
          loading={actionPending}
        >
          {actionText}
        </CustomButton>
      )}
    </>
  );
}

export default ActionButton;
