import CustomButton from "components/CustomButton";
import Input from "components/Inputs/Input";
import React, { useState } from "react";
import * as EmailValidator from "email-validator";
import useNotification from "hooks/notifications/useNotification";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { setMinterDetails } from "services/firebase";
import useWeb3 from "hooks/useWeb3";
function EmailSection() {
  const [email, setEmail] = useState<string | null>(null);
  const { showNotification } = useNotification();
  const [done, setDone] = useState(false);
  const { account } = useWeb3();

  const onSubmit = () => {
    if (!account) {
      return;
    }
    if (!email) {
      showNotification({
        message: "Email required",
        variant: "warning",
        closeAction: true,
      });
      return;
    }

    const str = email.replace(/\s/g, "");
    const result = EmailValidator.validate(str);

    if (!result) {
      showNotification({
        message: "Invalid email",
        variant: "warning",
        closeAction: true,
      });
      return;
    }

    setDone(true);
    setMinterDetails(account, str);
  };

  return (
    <div className="send-email">
      <Input
        value={email || ""}
        onUpdate={(_, value) => setEmail(value)}
        name=""
        placeholder="Enter email"
      />
      {done ? (
        <div className="send-email-success">
          <CheckCircleOutlineIcon />
          <p>Email sent</p>
        </div>
      ) : (
        <CustomButton onClick={onSubmit}>Submit</CustomButton>
      )}
    </div>
  );
}

export default EmailSection;
