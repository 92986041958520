import React from "react";
import FadingLine from "../../../../components/FadingLine";

function Lines() {
  return (
    <div className="home-lines">
      <div className="home-lines-left">
        {Array.from(Array(7).keys()).map((_, index) => {
          return <FadingLine opacity={index < 4 ? 0.12  : 0.2} id={`line-${index + 1}`} key={index} />;
        })}
      </div>
      <div className="home-lines-right">
        {Array.from(Array(7).keys()).map((_, index) => {
          return <FadingLine opacity={index < 4 ? 0.12  : 0.2} id={`line-${index + 1}`} key={index} />;
        })}
      </div>
    </div>
  );
}

export default Lines;
