import CustomButton from "components/CustomButton";
import Ipnut from "components/Inputs/Input";
import Popup from "components/Popup";
import React, {useState} from "react";
import Iframe from "./Iframe";
import LineImg from "assets/images/line.png";
import legend from "assets/images/legend.jpeg";
import {useDispatch} from "react-redux";
import {validateUser} from "./util";
import {PREMINT} from "consts";
import { sendWalletAddress } from "services/firebase";
import { toggleConnectModal } from "store/reducers/wallet";
import useWeb3 from "hooks/useWeb3";

const correntAnswer = "Where did the purple Monkey go".toLowerCase();

interface Props {
  onClose: () => void;
}

function NftNyc({ onClose }: Props) {
  const [answer, setAnswer] = useState("");
  const [error, setError] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const { account } = useWeb3();
  const [isLoading, setIsLoading] = useState(false);
  const [registrationError, setRegistrationError] = useState(false);
  const dispatch = useDispatch();

  const [registered, setIsRegistered] = useState<boolean | undefined>(false);
  const onPremintClick = () => {
    window.open(PREMINT);
  };

  const close = () => {
    setSubmitted(false);
    setIsLoading(false);
    onClose();
  };

  const onChange = (value: string) => {
    setAnswer(value.toLowerCase());
  };

  const submitAnswer = () => {
    if (answer !== correntAnswer) {
      setError(true);
    } else {
      sendWalletAddress(account)
      setSubmitted(true);
    }
  };

  const checkIfRegistered = async () => {
    try {
      setRegistrationError(false);
      setIsLoading(true);
      const result = await validateUser(account);
      if (!result) {
        setRegistrationError(true);
      }
      setIsRegistered(result);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const onConnect = () => {
    dispatch(toggleConnectModal(true));
  };

  return (
    <Popup className="nyc-popup" open onClose={close}>
      <div className="nyc">
        {submitted && <Iframe />}
        {/* {submitted && <Spinner color="black" />} */}
        <div className={`nyc-content ${submitted && "nyc-content-submitted"}`}>
          <div className="nyc-content-grid">
            <div className="nyc-top">
              <h2 className="nyc-title">Welcome, Degens</h2>
              <h3 className="nyc-subtitle">
                Here is your access to The Symbols allowlist FREE MINT
              </h3>
              <CustomButton onClick={onPremintClick}>Premint</CustomButton>
              <p className="nyc-required">
                You need to register with Premint first
              </p>
              <img alt="" src={LineImg} className="nyc-top-line" />
            </div>
            <div className="nyc-bottom">
              <section className="nyc-party">
                <p>
               <strong> Use the below key to solve Clue # 1 and unlock a special gift to be airdropped post Mint 🙃</strong>
                </p>

              </section>
              <img alt="" src={legend} className="nyc-img" />
              {registered && (
                <Ipnut
                  onFocus={() => setError(false)}
                  value={answer}
                  onUpdate={(_, value) => onChange(value)}
                  placeholder="Enter the answer..."
                  name=""
                  error={error ? "wrong answer" : undefined}
                  disabled={!registered}
                />
              )}

              {!account ? (
                <CustomButton id="nyc-submit" onClick={onConnect}>
                  Connect wallet to decipher
                </CustomButton>
              ) : !registered ? (
                <CustomButton
                  loading={isLoading}
                  id="nyc-submit"
                  onClick={checkIfRegistered}
                >
                  Tap to decipher clue # 1
                </CustomButton>
              ) : (
                <CustomButton id="nyc-submit" onClick={submitAnswer}>
                  Decipher
                </CustomButton>
              )}
              {registrationError && (
                <p className="nyc-registration-error">
                  We couldn't detect your address in the allowlist <br /> Please make
                  sure you are connected to the right wallet
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </Popup>
  );
}

export default NftNyc;
