import React, { useMemo } from "react";
import useMergeStore from "../../../../store/hooks/useMergeStore";
import steps from "../data";

function Step() {
  const { currentStepIndex } = useMergeStore();

  const StepComponent = useMemo(
    () => steps[currentStepIndex].component,
    [currentStepIndex]
  );

  return (
    <div className="merge-steps-step">
      <StepComponent />
    </div>
  );
}

export default Step;
