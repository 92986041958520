import React from "react";
const Fade = require("react-reveal/Fade");

interface Props {
  name: string;
  title: string;
  text: string[];
  year: string;
  image?: string;
}

function RoadmapItem({  title, text,  name, image }: Props) {
  return (
    <Fade bottom>
      <div className="home-roadmap-item">
        <figure className="home-roadmap-item-figure">
        <img src={image} alt="roadmap" className="home-roadmap-item-figure" />
       
        </figure>
         
        <section className="home-roadmap-item-description">
         
          <ul>
            {text.map((str, index) => {
              return <li key={index}>{str}</li>;
            })}
          </ul>
        </section>
      </div>
    </Fade>
  );
}

export default RoadmapItem;
