import React from "react";
import { useDispatch } from "react-redux";
import images from "../../../../consts/images";
import useMergeStore from "../../../../store/hooks/useMergeStore";
import { setWord } from "../../../../store/reducers/merge";
import Input from "../../../Inputs/Input";
import BaseStep from "./BaseStep";

const list = [
  { name: "tets1", image: images.home.brand1 },
  { name: "tets2", image: images.home.brand1 },
  { name: "tets2wefwefwefwefwefwefwef ewfwefwef", image: images.home.brand1 },
  { name: "tets2", image: images.home.brand1 },
  { name: "tets2", image: images.home.brand1 },
  { name: "tets2", image: images.home.brand1 },
  { name: "tets2", image: images.home.brand1 },
];

function WordSelectStep() {
  const { word } = useMergeStore();
  const dispatch = useDispatch()

  const onChange = (_name: string, value: string) => {
    dispatch(setWord(value))
  };

  return (
    <BaseStep
      image={list[0].image}
      header={
        <>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit risus vitae. Nisi vel commodo faucibus vel et,</p>
          <Input
            title="Your Word:"
            value={word}
            onUpdate={onChange}
            name="word"
            placeholder="Enter word..."
          />
        </>
      }
    />
  );
}

export default WordSelectStep;
