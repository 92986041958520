import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#00AAE1',
            dark: '#143C8C',
            contrastText: '#fff'
        }
    },
    typography: {
        // Use the system font over Roboto.
        fontFamily: 'Avenir Next, Roboto,"Helvetica Neue",Arial,sans-serif'
    }
});

export { theme };
