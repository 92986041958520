import React from "react";
import SectionHeader from "../SectionHeader";
import { data } from "./data";

function Perks() {
  return (
      <div className="perks">
        <SectionHeader
          title="UTILITY & PERKS "
          subtitle="for Genesis NFT holders"
        />
        <ul>
          {data.map((elem, index) => {
            return (
              <li key={index}>
                <img src={elem.image} alt='' />
                <p>{elem.text}</p>
              </li>
            );
          })}
        </ul>
      </div>
  );
}

export default Perks;
