import React from "react";
import Popup from "../../Popup";
import useLogic from "./useLogic";
import EtherscanLink from "../../EtherscanLink";
import Counter from "../Counter";
import TransactionError from "../TransactionError";
import env from "../../../config/env";
import ActionButton from "../ActionButton";
import Moonpay from "components/Moonpay";
import EmailSection from "./EmailSection";

function MintPopup() {
  const {
    onMint,
    onChange,
    close,
    txLoading,
    amount,
    transactionHash,
    success,
    error,
    hideError,
    mintsAllowed,
    onCloseBuyEth,
    disableButton,
    ethToPay,
    open,
    showInput,
    onShowEthClick,
    showMoonpay,
    moonpayUrl,
  } = useLogic();

  return (
    <>
      <Moonpay
        open={showMoonpay}
        onClose={onCloseBuyEth}
        url={moonpayUrl}
      />
      <TransactionError error={error} onClose={hideError} />
      <Popup className="mint-popup" open={open} onClose={close}>
        <>
          <h2 className="mint-popup-title">Mint</h2>
          <p className="mint-popup-text">
            {
              showInput ?
                  <>
                    Thank you for minting 4 paid symbols NFTs, you’ve qualified for our 1 of 5 signed Symbols art print, while supplies last.<br/><br/> Please provide your email below so we can reach out with details.
                  </> :
                  <>
                    <div style={{ textAlign: 'center'}}>
                      Below is the pricing and bonus breakdown for those who are able to support beyond the Free stage. <br/><br/>
                    </div>

                    1st NFT = Free <br/>
                    .04eth for the second <br/>
                    .08eth for the third <i style={{ fontStyle: 'italic'}}>*Holding 3 symbols at Snapshot qualifies you for Word Mint Airdrop</i> <br/>
                    .16eth for the 4th <br/>
                    .32eth for the 5th <i style={{ fontStyle: 'italic'}}>*Minting all 5 qualifies for Signed and numbered 1 of 5 Symbol art print</i>, while supplies last
                  </>
            }

          </p>
          {transactionHash && (
            <EtherscanLink url={`${env.etherscanUrl}/tx/${transactionHash}`} />
          )}
          {showInput ? (
            <EmailSection />
          ) : (
            <>
              <div>
                <Counter
                  amount={amount}
                  onChange={onChange}
                  maxAmount={mintsAllowed || 0}
                  disabled={txLoading}
                />
              </div>

              <p className="mint-popup-total">
                Total price: {Number(ethToPay) > 0 ? `${ethToPay} ETH` : "Free"}
              </p>
              <div className="mint-popup-action">
                {moonpayUrl ? (
                  <div>
                    <ActionButton
                      actionPending={false}
                      actionText="Buy ETH with MoonPay"
                      onAction={onShowEthClick}
                      disabled={disableButton}
                    />
                    <p className="mint-popup-action-warning">
                      You dont have balance
                    </p>
                  </div>
                ) : (
                  <ActionButton
                    disabled={!amount || success || disableButton}
                    actionPending={txLoading}
                    actionText={success ? "Mint Success" : "Mint"}
                    onAction={onMint}
                  />
                )}
              </div>
            </>
          )}
        </>
      </Popup>
    </>
  );
}

export default MintPopup;
