import React, { ReactNode } from "react";

interface Props {
  children: ReactNode;
  id?: string;
}

function ScreenGrid({ children, id = "" }: Props) {
  return (
    <div className="grid" id={id}>
      {children}
    </div>
  );
}

export default ScreenGrid;
