import { Dispatch } from "@reduxjs/toolkit";

const merge = async (_: string) => (dispatch: Dispatch) => {
  return 

  // const promiEvent = new TokenContract(web3).mint(address, "1");
  // promiEvent
  //   .once("transactionHash", (hash: string) => {})
  //   .once("error", (error: Error) => {
  //     dispatch(setError(error.message));
  //   })
  //   .once("receipt", async () => {
  //     dispatch(setSuccess(true));
  //     await delay(3000);
  //     dispatch(setSuccess(false));
  //   })
  //   .finally(async () => {
  //     dispatch(setPending(false));
  //   });
};

export { merge };

