import React, {useEffect} from "react";
import Navbar from "./components/Navbar";
import MintPopup from "./components/transactions/MintPopup";
import "./styles/index.scss";
import {RootRouter} from "./routes";
import Footer from "./components/Footer";
import MergePopup from "./components/transactions/MergePopup";
import useWeb3 from "hooks/useWeb3";
import SelectWallet from "components/SelectWallet";
import TokenContract from "contracts/token";
import useNetworkNotification from "hooks/notifications/useNetworkNotification";
import {useDispatch} from "react-redux";
import {
    setAccountMints,
    setGas,
    setIsAllowlistSaleOpen,
    setIsPublicSaleOpen,
    setMaxMintAmount,
} from "store/reducers/mint";
import {getEstimateGas} from "utils/web3";
import {useMintActions} from "store/reducers/mint/hooks";

const App = () => {
    const {tryConnectOnLoad, library, account} = useWeb3();
    useNetworkNotification();
    const dispatch = useDispatch();
    const {toggleConfigLoading} = useMintActions()

    useEffect(() => {
        tryConnectOnLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (library && account) {
            TokenContract.updateProvider(library);

            const getConfig = async () => {
                toggleConfigLoading(true)
                try {

                    const {maxMintAmount, isAllowlistOpen, isPublicSaleOpen} = await TokenContract.getConfig();
                    const gas = await getEstimateGas(library);
                    dispatch(setMaxMintAmount(maxMintAmount));
                    dispatch(setIsAllowlistSaleOpen(isAllowlistOpen));
                    dispatch(setIsPublicSaleOpen(isPublicSaleOpen));
                    dispatch(setGas(gas))
                    const totalPurchases = parseInt(
                        await TokenContract.getTotalMinted(account)
                    );
                    dispatch(setAccountMints(totalPurchases))
                } catch (error) {
                    console.log(error);
                } finally {
                    toggleConfigLoading(false)
                }
            };
            getConfig();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [library, dispatch, account]);


    return (
        <div className="App">
            <Navbar/>
            <MergePopup/>
            <MintPopup/>
            <RootRouter/>
            <Footer/>
            <SelectWallet/>
        </div>
    );
};

export default App;
