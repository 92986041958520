import {v4 as uuidv4} from "uuid";
import {OPENSEA_URL, TWITTER_URL} from "../../consts";
import images from "../../consts/images";

const getSocials = (light?: boolean) => {
  return [
    // {
    //   image: light ? images.socials.telegramWhite : images.socials.telegram,
    //   url: TELEGRAM_URL,
    //   id: uuidv4(),
    // },
    // {
    //   image: light ? images.socials.discordWhite : images.socials.discord,
    //   url: DISCORD_URL,
    //   id: uuidv4(),
    // },
    {
      image: light ? images.socials.openSeaWhite : images.socials.openSea,
      url: OPENSEA_URL,
      id: uuidv4(),
    },
    {
      image: light ? images.socials.twitterWhite : images.socials.twitter,
      url: TWITTER_URL,
      id: uuidv4(),
    },
  ];
};

export { getSocials };
