import { useDispatch } from "react-redux";
import { useCallback, useEffect, useRef, useState } from "react";
import useMintStore from "../../../store/hooks/useMintStore";
import { setError, setShowModal } from "../../../store/reducers/mint";
import useWeb3 from "hooks/useWeb3";
import {
  subscribeToBalanceChange,
} from "utils/web3";
import useNotification from "hooks/notifications/useNotification";
import { useMintActions, useMintDerivedData } from "store/reducers/mint/hooks";

function useLogic() {
  const {
    success,
    transactionHash,
    error,
    showModal: open,
    ethToPay,
    mintPending,
    moonpayUrl,
    validationPending,
  } = useMintStore();
  const { account, library } = useWeb3();
  const [amount, setAmount] = useState(1);
  const subscribeRef = useRef<any>();
  const balanceRef = useRef("0");
  const timeoutRef = useRef<any>();
  const { showNotification, hideNotification } = useNotification();
  const txLoadingNotificationKey = useRef<any>();
  const accountRef = useRef("");
  const [showMoonpay, setShowMoonpay] = useState(false)

  const { isMintedAll, mintsAllowed } = useMintDerivedData();
  const { mint, validateWalletBalance } = useMintActions();
  const amountRef = useRef(amount)

  const dispatch = useDispatch();


  useEffect(() => {
    setAmount(mintsAllowed > 0 ? 1 : 0)
  }, [mintsAllowed])
  

  const close = async () => {
    dispatch(setShowModal(false));
    if (mintPending) {
      txLoadingNotificationKey.current = showNotification({
        message: "Transaction pending",
        variant: "info",
        disableClose: true,
        action: () => dispatch(setShowModal(true)),
        actionText: "Show",
      });
    }
  };

  const onMint = useCallback(() => {
    mint(amount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount]);

  const hideError = () => {
    dispatch(setError(""));
  };


  const onChange = (value: number) => {
    setAmount(value);
    clearTimeout(timeoutRef.current);
    amountRef.current = value
    timeoutRef.current = setTimeout(() => {
      validateWalletBalance(value);
    }, 300);
  };


  const unsubscribe = () => {
    if (subscribeRef.current) {
      subscribeRef.current.unsubscribe();
    }
  };

  const onBalanceChange = async (bal: string) => {
    if (bal !== balanceRef.current) {  
      balanceRef.current =  bal
      validateWalletBalance( amountRef.current);
    }
  };


  const onOpen = async () => {
    if (!account) {
      unsubscribe();
      return;
    }
    validateWalletBalance(amount)
    unsubscribe();
  
    subscribeRef.current = subscribeToBalanceChange(
      account,
      onBalanceChange,
      library
    );
    validateWalletBalance(amount);
  };

  //Cleanup
  useEffect(() => {
    if (!open) {
      unsubscribe();
    } else {
      hideNotification(txLoadingNotificationKey.current);
      accountRef.current = account!!;
      onOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (account && accountRef.current && accountRef.current !== account) {
      onOpen();
      accountRef.current = account!!;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);




  return {
    onMint,
    onChange,
    close,
    txLoading: mintPending,
    amount,
    transactionHash,
    success,
    error,
    hideError,
    mintsAllowed,
    onCloseBuyEth: () => setShowMoonpay(false),
    disableButton: validationPending,
    ethToPay,
    open,
    showInput: isMintedAll,
    onShowEthClick:  () => setShowMoonpay(true),
    moonpayUrl,
    showMoonpay
  };
}

export default useLogic;
