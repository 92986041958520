import React from "react";
import { useDispatch } from "react-redux";
import useMergeStore from "../../../../../store/hooks/useMergeStore";
import useWalletStore from "../../../../../store/hooks/useWalletStore";
import { merge, setStep } from "../../../../../store/reducers/merge";
import CustomButton from "../../../../CustomButton";
import steps from "../../data";

interface Data {
  serum: string;
  word: string;
  letter: string;
}

const isNextStepAollowed = (dependency: any, data: Data) => {
  const result = data[dependency as keyof Data];
  return !!result;
};

function StepsButtons() {
  const { address } = useWalletStore();
  const {
    currentStepIndex,
    serum,
    word,
    letter,
    pending,
    success,
  } = useMergeStore();
  const dispatch = useDispatch();
  const data: Data = { serum, word, letter };
  const onNext = () => {
    dispatch(setStep(currentStepIndex + 1));
  };

  const onPrev = () => {
    dispatch(setStep(currentStepIndex - 1));
  };

  const onMerge = () => {
    if (address) {
      dispatch(merge(address));
    }
  };

  const disablePrev = currentStepIndex === 0;
  const showMergeButton = currentStepIndex >= steps.length - 1;
  return (
    <div className="merge-steps-btns">
      <CustomButton
        outlined
        style={{
          opacity: disablePrev ? 0 : 1,
          pointerEvents: disablePrev ? "none" : "all",
        }}
        disabled={disablePrev || pending}
        id="merge-prev"
        onClick={onPrev}
      >
        Back
      </CustomButton>
      {showMergeButton ? (
        <CustomButton
          disabled={success}
          loading={pending}
          id="merge-next"
          onClick={onMerge}
        >
          {success ? "Merge Success" : "Merge"}
        </CustomButton>
      ) : (
        <CustomButton
          disabled={
            !isNextStepAollowed(steps[currentStepIndex].dependency, data)
          }
          id="merge-next"
          onClick={onNext}
        >
          Next
        </CustomButton>
      )}
    </div>
  );
}

export default StepsButtons;
