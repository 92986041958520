import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import useWalletStore from "../../../store/hooks/useWalletStore";
import useMergeStore from "../../../store/hooks/useMergeStore";
import { clear, merge, setError, setShow } from '../../../store/reducers/merge';

function useLogic() {
  const { address } = useWalletStore();

  const dispatch = useDispatch()
  const {
    showPopup,
    pending,
    success,
    transactionHash,
    error,
  } = useMergeStore();

  const close = async () => {
    if (success) {
      dispatch(setShow(false));
      dispatch(clear())
    } else {
      dispatch(setShow(false));
      
    }
  };


  useEffect(() => {
    if(success && !showPopup){
      dispatch(clear())
    }
  }, [dispatch, showPopup, success])
  

  const onMerge = () => {
    if (address) {
      merge(address);
    }
  };

  const hideError = () => {
    dispatch(setError(''))
  }
  return {
    onMerge,
    close,
    open: showPopup,
    isLoading: pending,
    transactionHash,
    success,
    hideError,
     error
  };
}

export default useLogic;
