import React from "react";

import CustomButton from "../../../components/CustomButton";
import ScreenGrid from "../../../components/Screen/ScreenGrid";
import Video from "../../../components/Video";
import images from "../../../consts/images";
import videoSrc from "../../../assets/videos/metaverse/main.mp4";
import Lines from "./Lines";
import Shapes from "./Shapes";
import {OPENSEA_URL} from "../../../consts";

const Fade = require("react-reveal/Fade");

function Hero() {
  return (
    <div className="home-hero">
      <Lines />
      <Shapes />
      <ScreenGrid>
        <Fade delay={100}>
          <h4 className="home-hero-top-text">The Symbols</h4>
        </Fade>
        <Fade delay={200}>
          <h1 className="home-hero-title">
          A new alphabet and visual language for..........us
          </h1>
        </Fade>
        <Fade delay={300}>
          <div className="home-hero-text">
            <p>
              Our perceptions create our reality. Some will only see the world that is right in front of them, whereas others are aware of the truths existing beyond what we see.
            </p>

              <p>
                A generative NFT collection consisting of unique variations of 26 letters from The Symbols alphabet, built for and by the community. This is an opportunity to participate in defining what language can and should be.
              </p>

            <p>
              Language transcends, as such we are actively working to integrate ours across film, TV, media, advertising, music, fine art and Web3. Unlock secret messages, experiences in IRL and change the way we see what’s right in front of us.
            </p>
          </div>
        </Fade>
        {/*<div className="home-hero-button">*/}
        {/*  <Fade delay={500} bottom duration={250}>*/}
        {/*    <CustomButton onClick={() => window.open(PREMINT)}>*/}
        {/*      Join Premint*/}
        {/*    </CustomButton>*/}
        {/*  </Fade>*/}
        {/*</div>*/}
        <div className="home-hero-button">
          <Fade delay={900} bottom duration={500}>
            {/*<CustomButton onClick={() => history.push(ROUTES.allowlist)}>*/}
            {/*  Free Mint*/}
            {/*</CustomButton>*/}
            <CustomButton onClick={() => window.open(OPENSEA_URL)}>
              View On OpenSea
            </CustomButton>
          </Fade>
        </div>
        <div className="home-hero-video">
          <Fade duration={700} delay={500}>
            <Video muteControls poster={images.home.hero} video={videoSrc} />
          </Fade>
        </div>
      </ScreenGrid>
    </div>
  );
}

export default Hero;
