import React from "react";
import Navigation from "./Navigation";
import Socials from "../Socials";
import Connect from "./Connect";
import images from "../../consts/images";
import useScroll from "../../hooks/useScroll";
import { HashLink } from "react-router-hash-link";
import Mobile from "./Mobile";
import { ROUTES } from "../../consts";
function Navbar() {


  const scrollY = useScroll();

  const className = scrollY > 30 ? "navbar navbar-scrolled" : "navbar";

  return (
    <div className={className}>
      <div className="navbar-flex">
        <HashLink to={`${ROUTES.home.main}#`} smooth>
          <img src={images.navbar.logo} className="navbar-logo" alt="" />
        </HashLink>
        <div className="navbar-left">
          <Navigation />
          <Socials light />
          <Connect />
        </div>
        <Mobile />
      </div>
    </div>
  );
}

export default Navbar;
