import { MetaverseItemInterface } from "./types";
import image1 from "../../../assets/images/home/metaverse/item-1.png";
import image4 from "../../../assets/images/home/metaverse/item-4.png";
import image6 from "../../../assets/images/home/metaverse/item-6.png";
import image7 from "../../../assets/images/home/metaverse/item-7.png";
import image8 from "../../../assets/images/home/metaverse/item-8.png";

import video1 from "../../../assets/videos/metaverse/item-1.mp4";

import video4 from "../../../assets/videos/metaverse/item-4.mp4";
import video6 from "../../../assets/videos/metaverse/item-6.mov";
import video7 from "../../../assets/videos/metaverse/item-7.mov";
import video8 from "../../../assets/videos/metaverse/item-8.mov";


import { v4 as uuidv4 } from "uuid";

const data: MetaverseItemInterface[] = [
  {
    poster: image1,
    id: uuidv4(),
    video: video1,
  },
  {
    poster: image8,
    id: uuidv4(),
    video: video8,
  },
  {
    poster: image7,
    id: uuidv4(),
    video: video7,
  },

  {
    poster: image6,
    id: uuidv4(),
    video: video6,
  },
  {
    poster: image4,
    id: uuidv4(),
    video: video4,
  },
];

export default data;
