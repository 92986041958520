import React from "react";
import { symbols } from "./data";
const Fade = require("react-reveal/Fade");

function Symbols() {
  return (
    <Fade>
      <div className="founders-symbols">
        <h3 className="founders-symbols-title">Founders Symbols</h3>
        <div className="founders-symbols-list">
          {symbols.map((symbol) => {
            return <img src={symbol.image} key={symbol.id} alt="symbol" />;
          })}
        </div>
      </div>
    </Fade>
  );
}

export default Symbols;
