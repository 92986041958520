import React from "react";
import Indicator from "../../../components/Indicator";
import ScreenGrid from "../../../components/Screen/ScreenGrid";
import SectionHeader from "../SectionHeader";
import members from "./data";
import Member from "./Member";
import { MemberInterface } from "./types";

function Team() {
  return (
    <ScreenGrid>
      <div className="home-team">
        <Indicator id="team" />
        <SectionHeader
          title="The Symbols Team and Advisors"
          subtitle="The Symbols team have been operating at the intersection of technology, entertainment, music and web3"
        />

        <div className="home-team-members">
          {members.map((member: MemberInterface, index: number) => {
            return (
              <Member
                index={index}
                member={member}
                key={member.id}
                text={member.text}
              />
            );
          })}
        </div>
      </div>
    </ScreenGrid>
  );
}

export default Team;
