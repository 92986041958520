import { Network, networks } from "config";
import env from "config/env";
import useWeb3 from "hooks/useWeb3";
import React, { useEffect, useRef } from "react";
import { changeWalletNetwork } from "utils/web3";
import useNotification from "./useNotification";

function useNetworkNotification() {
  const { showNotification, hideNotification } = useNotification();
  const { isValidNetwork, library, chainId } = useWeb3();
  const notification = useRef<any>()

  const changeNetwork = async () => {
    try {
      await changeWalletNetwork(library);
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    if (chainId && !isValidNetwork) {
      const allowedNetwork = Number(env.network);

      const name = networks[allowedNetwork as Network].name;
      const message = `Switch to ${name}`;
      const msg = (
        <p>
          Wrong network
          <br />
          {message}
        </p>
      );
      notification.current =   showNotification({
        message: msg,
        variant: "info",
        disableClose: true,
        action: changeNetwork,
        actionText: "Switch",
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
      });
    } else {
      hideNotification(notification.current);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValidNetwork, chainId]);
}

export default useNetworkNotification;
