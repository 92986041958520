import {Box} from "@mui/material";
import {styled} from "@mui/styles";
import Popup from "components/Popup";
import Spinner from "components/Spinner";
import React, {useState} from "react";


const StyledLoader = styled(Box)({
    position: 'absolute',
    left: '46%',
    top: '45%',
    transform: 'translate(-50%, -50%)'
})

interface Props {
    open: boolean;
    onClose: () => void;
    url: string;
}

function Moonpay({open, onClose, url}: Props) {
    const [loading, setLoading] = useState(true);

    if (!url) {
        return null
    }

    return (
        <Popup open={open} className="moonpay" onClose={onClose}>
            {loading && (
                <StyledLoader>
                    <Spinner color="black"/>
                </StyledLoader>
            )}
            <iframe
                onLoad={() => setLoading(false)}
                title="moonpay"
                allow="accelerometer; autoplay; camera; gyroscope; payment"
                frameBorder="0"
                height="100%"
                src={url}
                width="100%"
            >
                {" "}
                <p>Your browser does not support iframes.</p>
            </iframe>
        </Popup>
    );
}

export default Moonpay;
