import { BaseInput, InputValidations } from "../../types";
import { v4 as uuidv4 } from 'uuid';

const inputs: BaseInput[] = [
  {
    name: "name",
    placeholder: "Name",
    validation: InputValidations.TEXT,
    id: uuidv4()
  },
  {
    name: "email",
    placeholder: "Email",
    validation: InputValidations.EMAIL,
    id: uuidv4()
  },
  {
    name: "comment",
    placeholder: "Write your comment",
    isTextArea: true,
    id: uuidv4()
  },
];


export default inputs