import { Switch, Route, Redirect } from "react-router-dom";
import React from "react";
import { HomeScreen, MergeScreen, MintScreen } from "../screens";
import { ROUTES } from "../consts";
import License from "screens/license";

export const RootRouter = () => {
  return (
    <Switch>
      <Route exact path={ROUTES.merge} component={MergeScreen} />
      <Route exact path={ROUTES.allowlist} component={MintScreen} />
      

      <Route path={ROUTES.license}  component={License}/>
      <Route exact path={ROUTES.home.nftNyc}>
      <HomeScreen showNftNyc={true} />
      </Route>

      <Route exact path={ROUTES.home.main} component={HomeScreen} />
      <Route render={() => <Redirect to={ROUTES.home.main} />} />
    </Switch>
  );
};
