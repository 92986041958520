import React from "react";
import { useDispatch } from "react-redux";
import images from "../../../../consts/images";
import useMergeStore from "../../../../store/hooks/useMergeStore";
import { setSerum } from "../../../../store/reducers/merge";
import SelectBox from "../../../SelectBox";
import BaseStep from "./BaseStep";

const list = [
  { name: "tets1", image: images.home.brand1 },
  { name: "tets2", image: images.home.brand1 },
  { name: "tets2wefwefwefwefwefwefwef ewfwefwef", image: images.home.brand1 },
  { name: "tets2", image: images.home.brand1 },
  { name: "tets2", image: images.home.brand1 },
  { name: "tets2", image: images.home.brand1 },
  { name: "tets2", image: images.home.brand1 },
];

function SerumSelectStep() {
  const { serum } = useMergeStore();
  const dispatch = useDispatch()
  const onSelect = (value: any) => {
    dispatch(setSerum(value.name));
  };

  return (
    <BaseStep
      image={list[0].image}
      header={
        <>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit risus vitae.
            Nisi vel commodo faucibus vel et,
          </p>
          <SelectBox
            title="Your Merge Serum:"
            list={list}
            selected={serum}
            onSelect={onSelect}
          />
        </>
      }
    />
  );
}

export default SerumSelectStep;
