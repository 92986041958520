import * as React from "react";
import Tooltip from "@mui/material/Tooltip";
import { isMobile } from "react-device-detect";

interface Props {
  text?: string | React.ReactFragment;
  children: React.ReactElement;
  placement?:
    | "bottom"
    | "left"
    | "right"
    | "top"
    | "bottom-end"
    | "bottom-start"
    | "left-end"
    | "left-start"
    | "right-end"
    | "right-start"
    | "top-end"
    | "top-start";
}

export default function BasicTooltip({
  text,
  children,
  placement = "bottom",
}: Props) {
  const [open, setOpen] = React.useState(false);
  return !text ? (
    children
  ) : isMobile ? (
    <div className="tooltip" onClick={() => setOpen(true)}>
      <Tooltip
        placement={placement}
        onClose={() => setOpen(false)}
        open={open}
        title={text}
      >
        <span className="tooltip-children">{children}</span>
      </Tooltip>
    </div>
  ) : (
    <Tooltip
      placement={placement}
      className="tooltip"
      title={text}
    >
      <span className="tooltip-children">{children}</span>
    </Tooltip>
  );
}
