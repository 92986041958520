import {MemberInterface} from './types';
import {v4 as uuidv4} from 'uuid';
import cto from "../../../assets/images/home/members/cto.png";
import pepe from "../../../assets/images/home/members/pepe.png";

import img8C from "../../../assets/images/home/members/8C.jpeg";
import t from "../../../assets/images/home/members/t.jpg";
import afro from "../../../assets/images/home/members/afro.jpeg";
import l3tr from "../../../assets/images/home/members/l3tr.jpeg";
import LOVEOFVENUS from "../../../assets/images/home/members/LOVEOFVENUS.jpg";

import nicb from "../../../assets/images/home/members/nicb.jpeg";
import rem from "../../../assets/images/home/members/rem.jpeg";
import sfoo from "../../../assets/images/home/members/sfoo.jpeg";


import Rectangle from 'assets/images/shapes/rectangle.svg'
import Square from 'assets/images/shapes/square.svg'
import Circle from 'assets/images/shapes/circle.svg'

import thedooronline from 'assets/images/home/members/thedooronline.jpeg'
import img0xb1 from "../../../assets/images/home/members/0xb1.jpeg";


const members: MemberInterface[] =  [
    {
        avatar: l3tr,
        shape: Square,
        name:'David',
        position:'Artist & Founder of The Symbols',
        text: '',
        id: uuidv4(),
        twitterName:'@L3TRLABS',
        twitterUrl:'https://twitter.com/L3TRLABS',
        instagramUrl:'https://www.linkedin.com/in/davidstein4/',
        instagramName:'David Stein'

    },
    // {
    //     avatar: aaronitio,
    //     shape: Circle,
    //     name:'Aaronito',
    //     position:'Project Manager',
    //     text: '',
    //     id: uuidv4(),
    //     twitterUrl: 'https://twitter.com/aaronitodoteth',
    //     twitterName: '@AaronitoDOTeth'
    // },
    {
        avatar: cto,
        shape: Rectangle,
        name:'Rotem Yakir',
        position:'Tech Advisor',
        id: uuidv4(),
        text: '',
        twitterUrl: 'https://twitter.com/yakirrotem',
        twitterName: '@yakirrotem'
    },

    {
        avatar: img0xb1,
        shape: Circle,
        name:'0xb1',
        position:'Project Advisor',
        id: uuidv4(),
        text: '',
        twitterName:'@0x_b1',
        twitterUrl: 'https://twitter.com/0x_b1'
    },


    {
        avatar: nicb,
        shape: Circle,
        name:'Nicole Behnam',
        position:'Community Lead',
        id: uuidv4(),
        text: '',
        twitterName:'@NicoleBehnam',
        twitterUrl: 'https://twitter.com/NicoleBehnam?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor'
    },

    {
        avatar: t,
        shape: Circle,
        name:'Doug Deluca',
        position:'Project Advisor',
        id: uuidv4(),
        text: '',
        twitterName: '@douglasdeluca',
        twitterUrl: 'https://www.linkedin.com/in/douglasdeluca/'
    },

    {
        avatar: LOVEOFVENUS,
        shape: Circle,
        name:'Zoe Schwartz',
        position:'Artist, Graphic Design',
        id: uuidv4(),
        text: '',
        twitterName: '@zoeroseschwartz',
        twitterUrl:'https://instagram.com/zoeroseschwartz?igshid=YmMyMTA2M2Y='
    },
    {
        avatar: afro,
        shape: Square,
        name:'Afrodet',
        position:'Project Advisor, Blockparty Curator at Large & NFT Now Advisor',
        id: uuidv4(),
        text: '',
        twitterName:'@afrodetz',
        twitterUrl: 'https://mobile.twitter.com/afrodetz'
    },

    {
        avatar: thedooronline,
        shape: Circle,
        name:'The Door',
        position:'Marketing / PR',
        id: uuidv4(),
        text: '',
        twitterName:'@thedooronline',
        twitterUrl:'https://twitter.com/thedooronline'
    },

    {
        avatar: pepe,
        shape: Square,
        name:'Javier Oliver',
        position:'Web Designer',
        id: uuidv4(),
        text: '',
        twitterName:'@javoliver88',
        twitterUrl:'https://twitter.com/javoliver88'
    },

    {
        avatar: sfoo,
        shape: Rectangle,
        name:'Scotty foo',
        position:'Web3 Degen, Advisor',
        id: uuidv4(),
        text: '',
        twitterName:'@ScottFooMusic',
        twitterUrl:'https://twitter.com/scottfoomusic'

    },

    // {
    //     avatar: jc,
    //     shape: Rectangle,
    //     name:'JC',
    //     position:'Discord security',
    //     id: uuidv4(),
    //     text: '',
    //     twitterName:'@jeancarlosxyz',
    //     twitterUrl:'https://twitter.com/jeancarlosxyz'
    // },
    {
        avatar: img8C,
        shape: Square,
        name:'8 commas',
        position:'Marketing',
        id: uuidv4(),
        text: '',
        twitterName:'@8Commas',
        twitterUrl:'https://twitter.com/8Commas'
    },
    {
        avatar: rem,
        shape: Rectangle,
        name:'Rembrandt',
        position:'Marketing / PR',
        id: uuidv4(),
        text: '',
        twitterName:'@rembrandtflores',
        twitterUrl:'https://twitter.com/rembrandtflores?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor'
    },
];

export default members
