/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
export interface State {
  showMintModal: boolean;
}

const initialState = {
  showMintModal: false,
} as State;

const MainSlice = createSlice({
  name: "main",
  initialState,
  reducers: {
    toggleMintModal(state, action: PayloadAction<boolean>) {
      state.showMintModal = action.payload;
    },
  },
});

export const { toggleMintModal } = MainSlice.actions;

export default MainSlice.reducer;
