import { useSelector } from "react-redux";
import { RootState } from "..";

function useMintStore() {
  const data = useSelector((state: RootState) => state.mint);

  return data
}

export default useMintStore;
