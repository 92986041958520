import React from "react";
import ScreenGrid from "../../components/Screen/ScreenGrid";
import TransactionScreen from "../../components/Screen/TransactionScreen";
import MintAction from "./MintAction";
import HeroImg from 'assets/images/mint/hero.jpg'

const Fade = require("react-reveal/Fade");

export const MintScreen = () => {
  return (
    <TransactionScreen id="mint">
      <ScreenGrid>
        <div className="mint-flex">
          <Fade left duration={500} delay={100}>
            <img src={HeroImg} alt="hero" className="mint-hero" />
          </Fade>
          <Fade>
            <div className="mint-description">
              <h2>Free-ish Mint</h2>
              <p className="description-text">
                Welcome to The Symbols mint. You are about to receive a token
                that will unlock untold rewards. Below is the pricing and bonus
                breakdown for those who are able to support beyond the Free
                stage.
                <br />
                <br />
                1st NFT = Free
                <br />
                 .04eth for the second
                 <br />
                 .08eth for the third{' '}
                <strong>
                  *Holding 3 symbols at Snapshot qualifies you for Word Mint
                  Airdrop
                </strong>
                <br />
                .16eth for the 4th
                <br />
                 .32eth for the 5th <strong>*Minting all 5 qualifies
                for Signed and numbered 1 of 5 Symbol art print, while supplies last</strong>
              </p>

              <MintAction />
            </div>
          </Fade>
        </div>
      </ScreenGrid>
    </TransactionScreen>
  );
};
