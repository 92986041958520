import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import * as serviceWorker from "./serviceWorker";
import store from "./store";
import { theme } from "./theme";
import App from "./App";
import Web3 from "web3";
import { Web3ReactProvider } from "@web3-react/core";
import { SnackbarProvider } from "notistack";

const getLibrary = (provider: any) => {
  provider.pollingInterval = 8000;
  return new Web3(provider);
};

ReactDOM.render(
  <Provider store={store}>
    <Router basename={process.env.PUBLIC_URL || "/"}>
      <ThemeProvider theme={theme}>
        <Web3ReactProvider getLibrary={getLibrary}>
          <SnackbarProvider maxSnack={3}>
          <App />
          </SnackbarProvider>
        </Web3ReactProvider>
      </ThemeProvider>
    </Router>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
