import { createAsyncThunk } from "@reduxjs/toolkit";
import env from "config/env";
import TokenContract from "contracts/token";
import api from "services/api";
import { RootState } from "store";
import { getUserEthBalance } from "utils/web3";

export const mintAllowlistAction = createAsyncThunk<
  // Return type of the payload creator
  number,
  { account?: string | null; amount: number }
>("mint/mintAllowlistAction", async ({ account, amount }) => {
  if (!account) {
    throw new Error("Account missing");
  }
  const price = await TokenContract.getAllowListMintPrice(account, amount);
  const details = TokenContract.getWhitelistedAccount(account);
  if (!details) {
    throw new Error("Account is not whitelisted");
  }

  const { v, r, s } = details;
  const params = {
    address: account,
    amount: amount.toString(),
    payableAmount: Number(price),
    whiteListDetails: { v, r, s },
  };

  await TokenContract.mintAllowlist(params);
  return amount;
});

export const mintPublicAction = createAsyncThunk<
  // Return type of the payload creator
  number,
  { account?: string | null; amount: number }
>("mint/mintPublicAction", async ({ account, amount }) => {
  if (!account) {
    throw new Error("Account missing");
  }
  const price = await TokenContract.getPublicMintPrice();
  await TokenContract.mint(account, amount.toString(), Number(price));
  return amount;
});

export const validateWalletBalanceAction = createAsyncThunk<
  // Return type of the payload creator
  { ethToPay: string; moonpayUrl: string },
  { account?: string | null; amount: number; library?: any }
>(
  "mint/validateWalletBalanceAction",
  async ({ account, amount, library }, thunkApi) => {
    if (!account || !library) {
      throw new Error("Account missing");
    }
    const data = thunkApi.getState() as RootState;

    const { gas } = data.mint;

    const price = await TokenContract.getAllowListMintPrice(account, amount);

    const balance = await getUserEthBalance(library, account);

    if (Number(balance) >= Number(price) + Number(gas)) {
      return {
        ethToPay: price,
        moonpayUrl: "",
      };
    }
    const etherToBuyAmount = Number(price) - Number(balance) + Number(gas);
    const url = `https://buy.moonpay.com?apiKey=${env.moonpayKey}&currencyCode=eth&quoteCurrencyAmount=${etherToBuyAmount}&walletAddress=${account}`;
    const result = await api.getMoonpaySignature(url);
    return {
      ethToPay: price,
      moonpayUrl: result,
    };
  }
);
