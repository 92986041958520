import { ClickAwayListener, IconButton } from "@mui/material";
import useWeb3 from "hooks/useWeb3";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toggleConnectModal } from "../../store/reducers/wallet";
import { makeElipsisAddress } from "../../utils";
import CustomButton from "../CustomButton";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import BasicTooltip from "components/CustomTooltip";

function Connect() {
  const { account, disconnect } = useWeb3();
  const dispatch = useDispatch();
  const [showDisconnect, setShowDisconnect] = useState(false);

  const onClickAway = () => {
    if (showDisconnect) {
      setShowDisconnect(false);
    }
  };




  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <div className="navbar-connect">
        {!account ? (
          <CustomButton onClick={() => dispatch(toggleConnectModal(true))}>
            Connect wallet
          </CustomButton>
        ) : (
          <div className="navbar-connect-connected">
            <BasicTooltip text={account} placement='bottom'>
            <p className="navbar-connect-connected-text">
              {makeElipsisAddress(account, 7)}
            </p>
            </BasicTooltip>
            <div className="navbar-connect-connected-toggle">
              <IconButton onClick={() => setShowDisconnect(!showDisconnect)}>
                <ArrowDropDownIcon style={{ color: "white" }} />
              </IconButton>
            </div>
            {showDisconnect && (
              <div className="navbar-disconnect">
                <CustomButton onClick={disconnect}>Disconnect</CustomButton>
              </div>
            )}
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
}

export default Connect;
