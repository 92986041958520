import { useWeb3React } from "@web3-react/core";
import env from "config/env";
import { connectors } from "config/web3";
const providerName = "provider-dclub";

function useWeb3() {
  const values = useWeb3React();
  const { deactivate, activate, chainId, error } = values;


  const tryConnectOnLoad = () => {
    if(!error){
      const provider = localStorage.getItem(providerName);
      if (provider) connect(connectors[provider], provider);
    }

  };

  const connect = (connector: any, type: string) => {
    activate(connector);
    window.localStorage.setItem(providerName, type);
  };

  const disconnect = () => {
    localStorage.removeItem(providerName);
    deactivate();
  };

  return {
    ...values,
    disconnect,
    connect,
    isValidNetwork: chainId ? chainId === Number(env.network) : false,
    tryConnectOnLoad,
    provider: localStorage.getItem(providerName),
  };
}

export default useWeb3;
