import React from "react";
const Fade = require("react-reveal/Fade");

interface Props {
  id?: string;
  direction?: string;
  opacity?: number;
}

function FadingLine({ id = "", opacity = 0.2 }: Props) {
  return (
    <Fade>
      <div className="fading-line" id={id}>
        <figure style={{opacity: opacity}}></figure>
      </div>
    </Fade>
  );
}

export default FadingLine;
