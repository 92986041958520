import React from "react";
import { FounderInterface } from "./types";
const Fade = require("react-reveal/Fade");

interface Props {
  founder: FounderInterface;
}

function Founder({ founder }: Props) {
  const {
    image,
    name,
    description,
    symbol,
    twitterName,
    twitterUrl,
    instagramName,
    instagramUrl,
    linkedinName,
    linkedinUrl,
  } = founder;
  return (
    <Fade>
      <div className="founder">
        <figure className="founder-frame">
          <img src={symbol} alt="founder" className="founder-symbol" />
          <div className="founder-frame-figure founder-frame-square"></div>
          <div className="founder-frame-figure founder-frame-circle"></div>
          <img src={image} alt="founder" className="founder-image" />
        </figure>
        <section className="founder-details">
          <h4 className="founder-details-title">{name}</h4>
          <p className="founder-details-text">{description}</p>

        
          <div
            style={{
              marginTop: "10px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {twitterName && (
              <a target="_blank" rel="noreferrer" href={twitterUrl} style={{marginBottom: 5}}>
                {twitterName}
              </a>
            )}
            {instagramUrl && (
              <a target="_blank" rel="noreferrer" href={instagramUrl} style={{marginBottom: 5}}>
                {instagramName}
              </a>
            )}

            {linkedinUrl && (
              <a target="_blank" rel="noreferrer" href={linkedinUrl} style={{marginBottom: 5}}>
                {linkedinName}
              </a>
            )}
          </div>
        </section>
      </div>
    </Fade>
  );
}

export default Founder;
