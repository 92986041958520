import Web3 from "web3";
import { AbiItem } from "web3-utils";
import { PromiEvent, TransactionReceipt } from "web3-core";
import Abi from "./abi/TokenAbi.json";
import env from "config/env";
import { fromWei, toWei } from "utils/web3";
import allowlist from "./allowlist.json";
import { TheSymbols } from "./TheSymbols";

type MintAllowlistParams = {
  address: string;
  payableAmount: number;
  amount: string;
  whiteListDetails: {
    v: string;
    r: string;
    s: string;
  };
};

class TokenContract {
  // @ts-ignore
  private static contract?: TheSymbols;

  public static updateProvider(provider?: any) {
    if (!provider) {
      this.contract = undefined;
    }
    try {
      const web3 = new Web3(provider);
      // @ts-ignore
      this.contract = new web3.eth.Contract(
        Abi as AbiItem[],
        env.tokenContract
      ) as TheSymbols;
    } catch (error) {
      throw new Error("Bad provider");
    }
  }

  public static async getAllowListMintPrice(address: string, amount: number) {
    if(!this.contract){
        throw new Error('Please connect')
    }
    const result = await this.contract?.methods
      .getAllowlistMintPrice(address, amount)
      .call();
    return fromWei(result);
  }

  public static async getPublicMintPrice() {
    if(!this.contract){
        throw new Error('Please connect')
    }
    const result = await this.contract.methods.publicMintPrice().call();
    return fromWei(result);
  }

  public static maxMintAllowlisted() {
    if(!this.contract){
        throw new Error('Please connect')
    }
    return this.contract.methods.maxMintAllowlisted().call();
  }

  public static maxMintPublic() {
    if(!this.contract){
        throw new Error('Please connect')
    }
    return this.contract.methods.maxMintPublic().call();
  }

  public static getTotalMinted(address: string) {
    if(!this.contract){
        throw new Error('Please connect')
    }
    return this.contract.methods.getTotalMinted(address).call();
  }

  public static getMintAllowlistEstimateGas() {}

  public static mint(
    address: string,
    amount: string,
    payableAmount: number
  ): PromiEvent<TransactionReceipt> {
    if(!this.contract){
        throw new Error('Please connect')
    }
    return this.contract.methods
      .mint(amount)
      .send({ from: address, value: toWei(payableAmount) });
  }

  public static mintAllowlist({
    address,
    payableAmount,
    amount,
    whiteListDetails: { v, r, s },
  }: MintAllowlistParams): PromiEvent<TransactionReceipt> {
    if(!this.contract){
        throw new Error('Please connect')
    }
    return this.contract.methods
      .mintAllowlist(amount, v, r, s)
      .send({ from: address, value: toWei(payableAmount) });
  }

  public static getWhitelistedAccount(address: string) {
    const list: any = allowlist;
    return list[Web3.utils.toChecksumAddress(address)];
  }

  public static allowlistSaleOpen() {
    // TODO: for testing change to true
    // return true;
    if(!this.contract){
        throw new Error('Please connect')
    }
    return this.contract.methods.allowlistSaleOpen().call();
  }

  public static publicSaleOpen() {
    if(!this.contract){
        throw new Error('Please connect')
    }
    return this.contract.methods.publicSaleOpen().call();
  }

  public static async getConfig() {
    const [
      isAllowlistOpen,
      isPublicSaleOpen,
      maxMintAllowlisted,
      maxMintPublic,
    ] = await Promise.all([
      this.allowlistSaleOpen(),
      this.publicSaleOpen(),
      this.maxMintAllowlisted(),
      this.maxMintPublic(),
    ]);

    return {
      isAllowlistOpen,
      isPublicSaleOpen,
      maxMintAmount: isAllowlistOpen
        ? Number(maxMintAllowlisted)
        : Number(maxMintPublic),
    };
  }
}

export default TokenContract;
