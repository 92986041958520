import React from "react";
import { isEven } from "../../utils";
import { BrandInterface } from "./types";
const Fade = require("react-reveal/Fade");

interface Props {
  data: BrandInterface;
  index: number;
}

function Brand({ data, index }: Props) {
  const { image, title, text } = data;

  const className = `brand ${isEven(index) ? 'brand-image-first' : 'brand-description-first'}`
  return (
    <div className={className}>
      <Fade duration = {500} bottom>
      <img src={image} alt="brand" className="brand-image" />
      <section className="brand-description">
        <h2>{title}</h2>
       <div className='brand-description-text'>
       {text}
       </div>
      </section>
      </Fade>
    </div>
  );
}

export default Brand;
