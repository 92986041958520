
import React  from "react";
import Popup from "../../Popup";
import CustomButton from "../../CustomButton";


interface Props {
    error: string;
    onClose: () => void;
}

function TransactionError({error, onClose}: Props) {

  return (
    <Popup className="tx-error" open={!!error} onClose={onClose}>
      <>
        <section className="transaction-error-message">
          <p>{error}</p>
        </section>
        <CustomButton onClick={onClose}>Close</CustomButton>
      </>
    </Popup>
  );
}

export default TransactionError;
