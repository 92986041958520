
const makeElipsisAddress = (address: string, padding?: number): string => {
    const paddingValue = padding || 10;
    if (!address) return '';
    const firstPart = address.substr(0, paddingValue);
    const secondPart = address.substr(address.length - paddingValue);
    return `${firstPart}...${secondPart}`;
};

const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));




const isEmptyObject = (obj: {}) =>  {
    return Object.keys(obj).length === 0;
}


const isEven = (n: number) =>  {
    return n % 2 === 0;
 }

 export const getParam = (name: string) => {
    const urlParams = new URLSearchParams(window.location.search);
    const result = urlParams.get(name)
    
    if(result){
        return JSON.parse(result)
    }

 }


 export const isMintTest = getParam('test')


export { makeElipsisAddress, delay,  isEven , isEmptyObject};
