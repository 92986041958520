import React from "react";
import { InputProps } from "./types";

function Input({
  value,
  name,
  onUpdate,
  placeholder,
  id = "",
  error,
  onFocus,
  onBlur,
  title,
  disabled,
}: InputProps) {
  const onChange = (value: string) => {
    onUpdate(name, value);
  };

  return (
    <div className="input-container">
      {title && <h5 className="input-container-title">{title}</h5>}
      <input
        disabled={disabled}
        id={id}
        className={`input ${error && "input-dirty"} ${
          disabled && "input-disabled"
        }`}
        value={value || ""}
        type="text"
        onFocus={() => onFocus && onFocus(name)}
        name={name}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        onBlur={onBlur}
      />
      {error && <p className="input-error">{error}</p>}
    </div>
  );
}

export default Input;
