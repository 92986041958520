import React from "react";
import images from "../../consts/images";

interface Props {
  id?: string;
  onClick: () => void;
}

function CloseButton({ id = "", onClick }: Props) {
return (
    <button onClick={onClick} className="close-btn">
      <img src={images.common.close} alt="close" />
    </button>
  );
}

export default CloseButton;
