import React from "react";
import StepsButtons from "./StepsButtons";
import Step from "./Step";
function MergeSteps() {
  return (
    <div className="merge-steps">
      <Step />
      <StepsButtons />
    </div>
  );
}

export default MergeSteps;
