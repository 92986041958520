import React, { useRef, useState } from "react";
import images from "../../consts/images";
import useClickOutside from "../../hooks/useClickOutside";
import { v4 as uuidv4 } from 'uuid';

interface Props {
  list: any[];
  selected: string;
  onSelect: (item: any) => void;
  title?: string; 
  placeholder?: string;
}

function SelectBox({ list, selected, onSelect, title, placeholder = 'Select...' }: Props) {
  const [showList, setshowList] = useState(false);
  const container = useRef(null);

  useClickOutside(container, () => setshowList(false));

    const Select = (value: any) => {
        onSelect(value) 
        setshowList(false)
    }

  return (
    <div className="select-box" ref={container}>
        {title && <h5 className='select-box-title'>{title}</h5>}
      <section
        className={`select-box-selected ${
          showList && "select-box-selected-open"
        }`}
        onClick={() => setshowList(!showList)}
      >
        {selected ? <p className="select-box-selected-text"> {selected}</p> : <p className="select-box-selected-placeholder"> {placeholder}</p>}
        <button>
          <img src={images.common.arrowBottom} alt="arrow" />
        </button>
      </section>
      {showList && (
        <ul className="select-box-list">
          {list.map((item) => {
            return (
              <li className="select-box-list-item" onClick={() => Select(item)} key = {uuidv4()}>
                {item.name}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}

export default SelectBox;
