/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { mintAllowlistAction, mintPublicAction, validateWalletBalanceAction } from "./actions";
export interface State {
  pending: boolean;
  transactionHash: string;
  error: string;
  showModal: boolean;
  success: boolean;
  maxMintAmount: number;
  isPublicSaleOpen: boolean;
  isAllowlistSaleOpen: boolean;
  gas: string | undefined;
  mintPending: boolean;
  mintError: undefined | string;
  mintSuccess: boolean;
  accountMints: number;
  moonpayUrl: string;
  validationPending: boolean;
  ethToPay: string;
  configLoading: boolean;
}

const initialState = {
  pending: false,
  transactionHash: "",
  error: "",
  showModal: false,
  success: false,
  maxMintAmount: 0,
  isPublicSaleOpen: false,
  isAllowlistSaleOpen: false,
  mintPending: false,
  mintSuccess: false,
  accountMints: 0,
  moonpayUrl: '',
  validationPending: true,
  ethToPay:'',
  configLoading: false
} as State;

const MintSlice = createSlice({
  name: "mint",
  initialState,
  reducers: {
    setToggleConfigLoading(state, action: PayloadAction<boolean>) {
      state.configLoading = action.payload;
    },
    setMaxMintAmount(state, action: PayloadAction<number>) {
      state.maxMintAmount = action.payload;
    },
    setIsPublicSaleOpen(state, action: PayloadAction<boolean>) {
      state.isPublicSaleOpen = action.payload;
    },
    setIsAllowlistSaleOpen(state, action: PayloadAction<boolean>) {
      state.isAllowlistSaleOpen = action.payload;
    },
    setPending(state, action: PayloadAction<boolean>) {
      state.pending = action.payload;
    },
    setSuccess(state, action: PayloadAction<boolean>) {
      state.success = action.payload;
    },
    setShowModal(state, action: PayloadAction<boolean>) {
      state.showModal = action.payload;
    },
    setGas(state, action: PayloadAction<string | undefined>) {
      state.gas = action.payload;
    },
    setAccountMints(state, action: PayloadAction<number>) {
      state.accountMints = action.payload;
    },
    setError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    setTransactionHash(state, action: PayloadAction<string>) {
      state.transactionHash = action.payload;
    },
  },
  extraReducers: (builder => {
      builder
      .addCase(mintAllowlistAction.fulfilled, (state, action) => {
        state.mintPending = false;
        state.mintSuccess = true;
        state.accountMints = state.accountMints + action.payload
      })
      .addCase(mintAllowlistAction.rejected, (state, action) => {
        state.mintPending = false;
        state.mintError = action.error.message;
      })
      .addCase(mintAllowlistAction.pending, (state, action) => {
        state.mintPending = true;
      })
      .addCase(mintPublicAction.fulfilled, (state, action) => {
        state.mintPending = false;
        state.mintSuccess = true;
        state.accountMints = state.accountMints + action.payload
      })
      .addCase(mintPublicAction.rejected, (state, action) => {
        state.mintPending = false;
        state.mintError = action.error.message;
      })
      .addCase(mintPublicAction.pending, (state, action) => {
        state.mintPending = true;
      })
      .addCase(validateWalletBalanceAction.pending, (state, action) => {
        state.validationPending = true;

      })
      .addCase(validateWalletBalanceAction.rejected, (state, action) => {
        state.validationPending = false;
        console.log(action.error);

      })
      .addCase(validateWalletBalanceAction.fulfilled, (state, action) => {
        state.validationPending = false;
        state.moonpayUrl = action.payload.moonpayUrl
        state.ethToPay = action.payload.ethToPay
      })
  })
});

export const {
  setPending,
  setSuccess,
  setShowModal,
  setError,
  setTransactionHash,
  setMaxMintAmount,
  setIsPublicSaleOpen,
  setIsAllowlistSaleOpen,
  setGas,
  setAccountMints,
  setToggleConfigLoading
} = MintSlice.actions;


export default MintSlice.reducer;
