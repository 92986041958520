import React from "react";
import FadingLine from "../../FadingLine";

function Lines() {
  return (
    <div className="transaction-screen-lines">
      {[...Array(6)].map((e, i) => {
        return <FadingLine opacity={i < 4 ? 0.1 : 0.2} key={`line-${i + 1}`} id={`line-${i + 1}`} />;
      })}
    </div>
  );
}

export default Lines;
