import React from "react";
import ScreenGrid from "../../components/Screen/ScreenGrid";
import TransactionScreen from "../../components/Screen/TransactionScreen";
import MergeAction from "./MergeAction";

const Zoom = require("react-reveal/Zoom");

export const MergeScreen = () => {
  return (
    <TransactionScreen id="merge">
      <ScreenGrid>
        <div className="merge-flex">
          {/*<Zoom duration={500} delay={100}>*/}
          {/* <div className="merge-hero" >*/}
          {/* <img src={HeroImg} alt="hero" />*/}
          {/* </div>*/}
          {/*</Zoom>*/}
          <Zoom duration={500} delay={100}>
            <div className="merge-description">
              <h2>Words mint coming soon</h2>
              <MergeAction />
            </div>
          </Zoom>
        </div>
      </ScreenGrid>
    </TransactionScreen>
  );
};
