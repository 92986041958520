import env from "./env";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import MetamaskImg from "assets/images/connect/metamask.png";
import WalletconnectImg from "assets/images/connect/walletconnect.png";
import CoinbaseImg from "assets/images/connect/coinbase.png";

const coinbaseWallet = new WalletLinkConnector({
  url: `${env.rpc}/${env.infura}`,
  appName: "The Symbols",

  
});

const injected = new InjectedConnector({

});

const walletconnect = new WalletConnectConnector({
  infuraId: env.infura,
  bridge: "https://bridge.walletconnect.org",
  qrcode: true,
});

export const connectors: any = {
  injected: injected,
  walletConnect: walletconnect,
  coinbase: coinbaseWallet,
};

export const connectorsOptions = [
  {
    type: connectors.injected,
    name: "injected",
    text: "Metamask",
    image: MetamaskImg,
  },
  {
    type: connectors.walletConnect,
    name: "walletConnect",
    text: "wallet Connect",
    image: WalletconnectImg,
  },
  {
    type: connectors.coinbase,
    name: "coinbase",
    text: "Coinbase",
    image: CoinbaseImg,
  },
];
