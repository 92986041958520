import Img1 from 'assets/images/home/perks/1.svg'
import Img2 from 'assets/images/home/perks/2.svg'
import Img3 from 'assets/images/home/perks/3.svg'
import Img4 from 'assets/images/home/perks/4.svg'
import Img5 from 'assets/images/home/perks/5.svg'
import Img6 from 'assets/images/home/perks/6.svg'
import Img7 from 'assets/images/home/perks/7.svg'
import Img8 from 'assets/images/home/perks/8.svg'


const data = [
    {
        image: Img1,
        text: 'Voting rights in The Symbols DAO'
    },
    {
        image: Img2,
        text: 'Individual NFT holders reserve right to purchase the physical work of NFT they own'
    },
    {
        image: Img3,
        text: 'Voting on limited edition art prints to be release'
    },
    {
        image: Img4,
        text: 'Secret messaging dApp to send and read encrypted messages using The Symbols alphabet'
    },
    {
        image: Img5,
        text: 'Future allowlist and private sale spots'
    },
    {
        image: Img6,
        text: "Gated premium Discord channels for 'The Symbols’ holders"
    },
    {
        image: Img7,
        text: 'First access to 1 of 1 physical and Digital works'
    },
    {
        image: Img8,
        text: 'Exclusive access to brand collaborations'
    }
]

export {data}
