import React from "react";
import Popup from "../../Popup";
import useLogic from "./useLogic";
import MergeProgress from './MergeProgress'
import MergeSteps from './MergeSteps'
import CloseButton from "../../CloseButton";
import TransactionError from "../TransactionError";
function MergePopup() {
  const {
    close,
    open,
    hideError,
    error
  } = useLogic();

  return (
    <Popup open={open} onClose={close}>
        <TransactionError error={error} onClose={hideError} />
       <div className="merge">
          <CloseButton onClick = {close} />
           <MergeProgress />
           <MergeSteps />
        </div>
    </Popup>
  );
}

export default MergePopup;
