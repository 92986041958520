import React from "react";
import useMergeStore from "../../../../store/hooks/useMergeStore";
import steps from "../data";
import { StepInterface } from "../types";

import selectedImg from "../../../../assets/images/merge/selected.png";
import doneImg from "../../../../assets/images/merge/done.png";
import pendingImg from "../../../../assets/images/merge/not-done.svg";
import { State } from "../../../../store/reducers/merge/types";

const getStepStatus = (step: StepInterface, stepIndex: number, data: State) => {
  const { currentStepIndex } = data;
  const { dependency } = step;
  if (stepIndex === currentStepIndex) {
    return {  id: "active-step" };
  }
  if (dependency && data[dependency as keyof State]) {
    return {  id: "done-step" };
  } else {
    return {  id: "pending-step" };
  }
};

function MergeProgress() {
  const data = useMergeStore();
  return (
    <div className="merge-progress">
      <h2>Merge</h2>
      <div className="merge-progress-steps">
        {steps.map((step, index) => {
          const {  id } = getStepStatus(step, index, data);

          return (
            <section className="merge-progress-step" id={id} key={step.id}>
              <img
                src={selectedImg}
                alt="status"
                id='merge-active-image'
                className="merge-progress-step-image"
              />
               <img
                src={doneImg}
                alt="status"
                id='merge-done-image'
                className="merge-progress-step-image"
              />
               <img
                src={pendingImg}
                alt="status"
                id='merge-pending-image'
                className="merge-progress-step-image"
              />
              <h5 className="merge-progress-step-title">{step.title}</h5>
            </section>
          );
        })}
      </div>
    </div>
  );
}

export default MergeProgress;
