import { NavigationInterface, NAVIGATION_TYPES } from "../types/index";
/* eslint-disable no-undef */

const ROUTES = {
  home: {
    main: "/",
    team: "/#team",
    roadmap: "/#roadmap",
    founders: "/#founders",
    nftNyc: '/NFTNYC'

  },
  allowlist: "/allowlist",
  merge: "/merge",
  license: '/license'
};

const NAVIGATION: NavigationInterface[] = [
  {
    title: "Allowlist",
    path: ROUTES.allowlist,
    key: "allowlist",
    type: NAVIGATION_TYPES.LINK,
  },
  {
    title: "⬜⬜⬜⬜⬜",
    path: ROUTES.merge,
    key: "merge",
    type: NAVIGATION_TYPES.LINK,
  },
  {
    title: "Team",
    path: ROUTES.home.team,
    key: "team",
    type: NAVIGATION_TYPES.HASH,
  },
  {
    title: "Roadmap",
    path: ROUTES.home.roadmap,
    key: "roadmap",
    type: NAVIGATION_TYPES.HASH,
  },
  // {
  //   title: "Brands",
  //   path: "/brands",
  //   key: "brands",
  //   type: NAVIGATION_TYPES.HASH,
  // },
  {
    title: "Founders",
    path: ROUTES.home.founders,
    key: "founders",
    type: NAVIGATION_TYPES.HASH,
  },
];

export const TELEGRAM_URL = "";
export const TWITTER_URL = "https://twitter.com/thesymbolsnft";
export const OPENSEA_URL = "https://opensea.io/collection/the-symbols-nft";
export const DISCORD_URL = "";
export const PREMINT = 'https://www.premint.xyz/the-symbols/'

export { ROUTES, NAVIGATION };
