import React, {useCallback, useEffect} from "react";
import Popup from "components/Popup";
import {useDispatch} from "react-redux";
import {connectors, connectorsOptions} from "config/web3";
import useWeb3 from "hooks/useWeb3";
import useWalletStore from "store/hooks/useWalletStore";
import {toggleConnectModal} from "store/reducers/wallet";

const filterConnectots = () => {
  if (!(window as any).ethereum) {
    return connectorsOptions.filter((c) => c.type !== connectors.injected);
  }
  return connectorsOptions;
};

export default function SelectWallet() {
  const { connect, active, error } = useWeb3();
  const { showConnectModal } = useWalletStore();
  const dispatch = useDispatch();

  const close = useCallback(() => {
    dispatch(toggleConnectModal(false));
  }, [dispatch]);

  const select = (connector: any, type: string) => {
    try {
      connect(connector, type);
    } catch (error) {
      console.log(error);
    }
  };
  if (error) {
    console.log(error);
  }

  useEffect(() => {
    if (active) {
      close();
    }
  }, [active, close]);

  return (
    <Popup open={showConnectModal} onClose={close} className="wallet-select">
      <>
        <h3>Select wallet</h3>
        <ul className="wallet-select-list">
          {filterConnectots().map((connector) => {
            return (
              <li
                key={connector.name}
                onClick={() => {
                  select(connector.type, connector.name);
                }}
              >
                <img alt="" src={connector.image} />
                <p>{connector.text}</p>
              </li>
            );
          })}
        </ul>
      </>
    </Popup>
  );
}
