import { v4 as uuidv4 } from "uuid";

import fall from '../../../assets/images/home/roadmap/fall.svg'
import beyond from '../../../assets/images/home/roadmap/beyond.svg'
import summer from '../../../assets/images/home/roadmap/summer.svg'

const data = [
  // {
  //   name: "Spring",
  //   title: "Website launch & Mint announcement",
  //   text: [
  //     "Allowlist announcement and sale",
  //     "Genesis mint ",
  //     "Airdrop date announcement",
  //   ],
  //   year: "2022",
  //   id: uuidv4(),
  //   image: spring,
  // },
  {
    name: "Summer",
    title: "Genesis Mint goes live",
    text: [
      "Allowlist announcement and sale",
      "Genesis mint",
      "NFT NYC Panel + Yacht party w Blondish",
      "Airdrop date announcement",
      "Airdrop goes live",
      "Word mint goes live",
      "Brand and platform partnership announcements"
    ],
    year: "2022",
    id: uuidv4(),
    image: summer,
  },
  {
    name: "Fall",
    title: "Words mint and more ",
    text: [
      "Gallery Art Show in NYC",
      "Word game development",
      "AR app launch",
      "DAO and charitable foundation partners launch"
    ],
    year: "2022",
    id: uuidv4(),
    image:fall,
  },
  {
    name: "Beyond",
    title: "Metaverse and IRL brand collaborations",
    text: [
      "March 2023 midnight moment Time Square billboard takeover",
      "Ongoing artist and project collaborations",
    ],
    year: "2022",
    id: uuidv4(),
    image: beyond,
  },
];

export default data;
