import { useSelector } from "react-redux";
import { RootState } from "..";

function useMergeStore() {
  const data = useSelector((state: RootState) => state.merge);

  return data;
}

export default useMergeStore;
