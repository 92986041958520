import React, { CSSProperties, ReactNode } from "react";
import Spinner from "../Spinner";
import { LoadingButton } from "@mui/lab";

interface Props {
  onClick?: () => void;
  children: ReactNode;
  disabled?: boolean;
  loading?: boolean;
  id?: string;
  outlined?: boolean;
  style?: CSSProperties;
  allowClickWhileLoading?: boolean;
}

function CustomButton({
  onClick,
  children,
  disabled,
  loading = false,
  id = "",
  outlined,
  allowClickWhileLoading,
}: Props) {
  const isDisabled = allowClickWhileLoading ? false : disabled || loading;

  return (
    <LoadingButton
    className="main-button"
    loading={loading}

      style={{
        background: outlined ? "white" : "#4743FF",
        border: outlined ? " 1px solid #4743FF" : "",
        fontSize: 14,
        fontWeight: 600,
        color: 'white',
        opacity: disabled ? 0.4 : loading ? 0.6  : 1,
        textTransform:'unset',
        paddingLeft: 20,
        paddingRight: 20,
        borderRadius:0,
        pointerEvents: loading ? 'none' : 'all'
      }}
      loadingIndicator ={<Spinner  size={20} />}
      
      id={id}
      onClick={onClick}
      disabled={isDisabled}
    >
      <div style={{opacity: loading ? 0 : 1}}>{children}</div>
    </LoadingButton>
  );
}

export default CustomButton;
