import React from "react";
import Screen from "../../components/Screen";
import Brand from "./Brand";
import brands from "./data";
import { BrandInterface } from "./types";
import Contact from "../../components/Contact";
import ScreenGrid from "../../components/Screen/ScreenGrid";
const Fade = require("react-reveal/Fade");

function BrandsScreen() {
  return (
    <Screen id="brands">
      <ScreenGrid id="brands-grid">
        <header className="brands-header">
         <Fade delay={100}> 
         <h3>Small description here</h3>
         </Fade>
          <Fade bottom delay={100} duration={500}>
          <h1 >Lorem Brand Sit</h1>
          </Fade>
         <Fade  bottom delay={200} duration={500}>
         <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit risus vitae.
            Nisi vel commodo faucibus vel et, semper Consectetur risus, ornare
            id eu, arcu, ipsum.
          </p>
         </Fade>
        </header>
        <div className="brands-list">
          {brands.map((brand: BrandInterface, index: number) => {
            return <Brand data={brand} key={brand.id} index={index} />;
          })}
        </div>
      </ScreenGrid>
      <Contact />
    </Screen>
  );
}

export default BrandsScreen;
