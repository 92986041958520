import React from "react";

interface Props {
  id: string;
}

function Indicator({ id }: Props) {
  return <aside id={id} className='indicator' />;
}

export default Indicator;
