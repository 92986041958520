import React from "react";
import { InputProps } from "./types";

function Textarea({ value, name, onUpdate, placeholder, id = "", onFocus }: InputProps) {
  const onChange = (value: string) => {
    onUpdate(name, value);
  };

  return (
    <div className="textarea-container">
      <textarea
        className="textarea"
        id={id}
        onFocus={() => onFocus && onFocus(name)}
        value={value}
        name={name}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
      />
    </div>
  );
}

export default Textarea;
