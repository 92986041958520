import * as React from 'react';
import images from "../../../consts/images";

interface IProps {
  amount: number;
  onChange: (val: number) => void;
  maxAmount: number;
  prefix?: string;
  disabled?: boolean;
}

function Counter({ amount, onChange, maxAmount, prefix, disabled }: IProps) {
  const handleAmount = (val: string) => {
    const value = val.replace(/[^0-9.]/g, "").replace(/(\..*?)\..*/g, "$1");
    if (Number(value) <= maxAmount) {
      onChange(Number(value));
    }
  };

  const increment = () => {
    if(!disabled){
      handleAmount(String(amount + 1));
    }
   
  };

  const decrement = () => {
    if (amount > 1 && !disabled) {
      handleAmount(String(amount - 1));
    }
  };

  const disabledCounter = maxAmount === 0;

  return (
    <section className="counter" style ={{pointerEvents: disabled ? 'none' : 'all', opacity: disabled ? 0.7 : 1}}>
      <h5 className='counter-title'>Amount to Mint:</h5>
      <div className="input-container">
        <input
          type="string"
          placeholder="5"
          value={disabledCounter ? 0 : prefix ? `${amount}${prefix}` : amount}
          onChange={(e) => handleAmount(e.target.value)}
        />
        <div className="input-container-btns">
          <button
            className="input-container-btns-top"
            onClick={increment}
            style={{
              opacity: disabledCounter
                ? 0.3
                : maxAmount === Number(amount)
                ? 0.3
                : 1,
              pointerEvents: disabledCounter
                ? "none"
                : maxAmount === Number(amount)
                ? "none"
                : "all",
            }}
          >
            <img src={images.common.arrowBottom} alt="arrow top" />
          </button>
          <button
            className="input-container-btns-bottom"
            onClick={decrement}
            style={{
              opacity: Number(amount) === 1 ? 0.3 : 1,
              pointerEvents: Number(amount) === 1 ? "none" : "all",
            }}
          >
            <img src={images.common.arrowBottom} alt="arrow bottom" />
          </button>
        </div>
      </div>
    </section>
  );
}

export default Counter;
