import React from "react";
import { v4 as uuidv4 } from 'uuid';

import images from "../../consts/images";
import { BrandInterface } from "./types";

const brands: BrandInterface[] = [
  {
    id: uuidv4(),
    title: "Apple Lorem Ipsum Set",
    image: images.brands.brandApple,
    text: (
      <>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit risus vitae.
          Nisi vel commodo faucibus vel et, semper Consectetur risus, ornare id
          eu, arcu, ipsum. lorem ipsum dolore.
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit risus vitae.
          Nisi vel commodo faucibus vel et, semper Consectetur risu.
        </p>
        <p>
          Nisi vel commodo faucibus vel et, semper Consectetur risus, ornare id
          eu, arcu, ipsum. lorem ipsum dolore.
        </p>
      </>
    ),
  },
  {
    id: uuidv4(),
    title: "Apple Lorem Ipsum Set",
    image: images.brands.brandApple,
    text: (
      <>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit risus vitae.
          Nisi vel commodo faucibus vel et, semper Consectetur risus, ornare id
          eu, arcu, ipsum. lorem ipsum dolore.
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit risus vitae.
          Nisi vel commodo faucibus vel et, semper Consectetur risu.
        </p>
        <p>
          Nisi vel commodo faucibus vel et, semper Consectetur risus, ornare id
          eu, arcu, ipsum. lorem ipsum dolore.
        </p>
      </>
    ),
  },
  {
    id: uuidv4(),
    title: "Apple Lorem Ipsum Set",
    image: images.brands.brandApple,
    text: (
      <>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit risus vitae.
          Nisi vel commodo faucibus vel et, semper Consectetur risus, ornare id
          eu, arcu, ipsum. lorem ipsum dolore.
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit risus vitae.
          Nisi vel commodo faucibus vel et, semper Consectetur risu.
        </p>
        <p>
          Nisi vel commodo faucibus vel et, semper Consectetur risus, ornare id
          eu, arcu, ipsum. lorem ipsum dolore.
        </p>
      </>
    ),
  },
  {
    id: uuidv4(),
    title: "Apple Lorem Ipsum Set",
    image: images.brands.brandApple,
    text: (
      <>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit risus vitae.
          Nisi vel commodo faucibus vel et, semper Consectetur risus, ornare id
          eu, arcu, ipsum. lorem ipsum dolore.
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit risus vitae.
          Nisi vel commodo faucibus vel et, semper Consectetur risu.
        </p>
        <p>
          Nisi vel commodo faucibus vel et, semper Consectetur risus, ornare id
          eu, arcu, ipsum. lorem ipsum dolore.
        </p>
      </>
    ),
  },
  {
    id: uuidv4(),
    title: "Apple Lorem Ipsum Set",
    image: images.brands.brandApple,
    text: (
      <>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit risus vitae.
          Nisi vel commodo faucibus vel et, semper Consectetur risus, ornare id
          eu, arcu, ipsum. lorem ipsum dolore.
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit risus vitae.
          Nisi vel commodo faucibus vel et, semper Consectetur risu.
        </p>
        <p>
          Nisi vel commodo faucibus vel et, semper Consectetur risus, ornare id
          eu, arcu, ipsum. lorem ipsum dolore.
        </p>
      </>
    ),
  },
];

export default brands;
