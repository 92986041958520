/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface State {
  isConnecting: boolean;
  showConnectModal: boolean;
}

const initialState = {
  isConnecting: false,
  showConnectModal: false,
} as State;

const WalletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    toggleConnectModal(state, action: PayloadAction<boolean>) {
      state.showConnectModal = action.payload;
    },
    setConnecting(state, action: PayloadAction<boolean>) {
      state.isConnecting = action.payload;
    },
  },
});

export const { setConnecting, toggleConnectModal } = WalletSlice.actions;
export default WalletSlice.reducer;
