import React from 'react';
import Link from '@mui/material/Link';
import LaunchIcon from '@mui/icons-material/Launch';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface Props {
    url: string;
}

const useStyles = makeStyles({
    container: {
        display: 'flex',
        alignItems: 'center'
    }
});

function EtherscanLink({ url }: Props) {
    const classes = useStyles();
    return (
        <Link href={url} target="_blank" className={classes.container}>
            <Typography>View on etherscan</Typography>
            <LaunchIcon />
        </Link>
    );
}

export default EtherscanLink;
