import React from "react";
import Indicator from "../../../components/Indicator";
import ScreenGrid from "../../../components/Screen/ScreenGrid";
import images from "../../../consts/images";
import SectionHeader from "../SectionHeader";
import roadmap from "./data";
import RoadmapItem from "./RoadmapItem";
import BkgImg from "../../../assets/images/home/roadmap/bkg.png";
const Fade = require("react-reveal/Fade");

function Roadmap() {
  return (
    <div className="home-roadmap">
      <Fade left>
        <img
          src={images.common.shape4}
          alt="shape"
          className="home-roadmap-shape-1"
        />
      </Fade>
      <Fade right>
        <img
          src={images.common.shape5}
          alt="shape"
          className="home-roadmap-shape-2"
        />
      </Fade>
      <Indicator id="roadmap" />
      <ScreenGrid>
        <SectionHeader
          title="Where we're going we don't need roads"
          subtitle={<>The future is uncertain but you can certainly bet on our extremely talented
              and dedicated team to bring the most exciting productions, technology and ideas to life.
              <br/>
              <br/>
              Below is a list of upcoming activity but, expect the unexpected…</>}
        />
        <div className="home-roadmap-steps">

          {roadmap.map((item) => {
            return (
              <RoadmapItem
                year={item.year}
                title={item.title}
                text={item.text}
                name={item.name}
                key={item.id}
                image={item.image}
              />
            );
          })}
          <img src={BkgImg} alt="" className="home-roadmap-steps-bkg" />
        </div>
      </ScreenGrid>
    </div>
  );
}

export default Roadmap;
