import { StepInterface } from "./types";
import { v4 as uuidv4 } from "uuid";
import LetterSelectStep from "./MergeSteps/LetterSelectStep";
import MergeResultStep from "./MergeSteps/MergeResultStep";
import SerumSelectStep from "./MergeSteps/SerumSelectStep";
import WordSelectStep from "./MergeSteps/WordSelectStep";

const steps: StepInterface[] = [
  {
    title: "Select your Letter",
    id: uuidv4(),
    component: LetterSelectStep,
    dependency: "letter",
  },
  {
    title: "Select your Merge Serum",
    id: uuidv4(),
    component: SerumSelectStep,
    dependency: "serum",
  },
  {
    title: "Enter a Word",
    id: uuidv4(),
    component: WordSelectStep,
    dependency: "word",
  },
  { title: "Your Merge Result", id: uuidv4(), component: MergeResultStep },
];

export default steps;
