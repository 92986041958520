import axios from "axios";
import env from "config/env";

const getMoonpaySignature = async (url: string) => {
  const result = await axios.post(`${env.apiEndpoint}/moonpay-url`, {
    url,
  });
  return result.data.url;
};

const api = {
  getMoonpaySignature,
};

export default api;
