import React, { ReactNode } from "react";
import LoadingImage from "../../../LoadingImage";

interface Props {
  image: string;
  header: ReactNode;
  id?: string;
}

function BaseStep({ header, image, id = "" }: Props) {
  return (
    <div className="base-step" id={id}>
      <div className='base-step-header'>
      {header}
      </div>
      <div className="base-step-image">
        <LoadingImage src={image} />
      </div>
    </div>
  );
}

export default BaseStep;
