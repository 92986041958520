import { v4 as uuidv4 } from "uuid";
import React from "react";

import Symbol6 from "../../../assets/images/home/founders/symbols/symbol-6.png";
import Symbol7 from "../../../assets/images/home/founders/symbols/symbol-7.png";
import Symbol8 from "../../../assets/images/home/founders/symbols/symbol-8.png";

import img0xb1 from "../../../assets/images/home/members/0xb1.jpeg";
import t from "../../../assets/images/home/members/t.jpg";
import image1 from "../../../assets/images/home/members/image1.jpg";
import b from "../../../assets/images/home/members/b.jpg";

import symbol1 from "assets/images/symbols/1.png";
import symbol2 from "assets/images/symbols/2.png";
import symbol3 from "assets/images/symbols/3.png";
import symbol4 from "assets/images/symbols/4.png";
import symbol5 from "assets/images/symbols/5.png";
import symbol6 from "assets/images/symbols/6.png";
import symbol7 from "assets/images/symbols/7.png";
import symbol8 from "assets/images/symbols/8.png";
import symbol9 from "assets/images/symbols/9.png";
import symbol10 from "assets/images/symbols/10.png";
import symbol11 from "assets/images/symbols/11.png";
import symbol12 from "assets/images/symbols/12.png";
import symbol13 from "assets/images/symbols/13.png";
import symbol14 from "assets/images/symbols/14.png";
import symbol15 from "assets/images/symbols/15.png";
import symbol16 from "assets/images/symbols/16.png";
import symbol17 from "assets/images/symbols/17.png";
import symbol18 from "assets/images/symbols/18.png";
import symbol19 from "assets/images/symbols/19.png";
import symbol20 from "assets/images/symbols/20.png";

const founders = [
  {
    name: "0xb1",
    image: img0xb1,
    id: uuidv4(),
    description: "Defi Degen, NFT collector",
    symbol: Symbol7,
    twitterName: "@0x_b1",
    twitterUrl: "https://twitter.com/0x_b1",
  },
  {
    name: "Doug Deluca",
    image: t,
    id: uuidv4(),
    description: "Executive producer, Jimmy Kimmel Live",
    symbol: Symbol6,
    linkedinName:'@douglasdeluca',
    linkedinUrl:'https://www.linkedin.com/in/douglasdeluca/'
  },
  {
    name: "Zach Iser",
    image: image1,
    id: uuidv4(),
    description:
      "Partner & Co-Head of Hip Hop & R&B Music at WME (William Morris Endeavor)",
    symbol: Symbol8,
    twitterName: "@ZachIser",
    twitterUrl: "https://twitter.com/ZachIser",
  },
  {
    name: "Brendan Fallis & Hannah Bronfman",
    image: b,
    id: uuidv4(),
    description: (
      <>
        Creators, investors & founders of{" "}
        <a href="https://preveyor.com/" rel="noreferrer" target="_blank">
          preveyor.com/HBFIT
        </a>
      </>
    ),
    symbol: symbol2,
    twitterName: "@HannahBronfman",
    twitterUrl: "https://twitter.com/HannahBronfman",
    instagramName: '@brendanfallis',
    instagramUrl:'https://instagram.com/brendanfallis?igshid=YmMyMTA2M2Y='
  },
];

const symbols = [
  {
    image: symbol1,
    id: uuidv4(),
  },
  {
    image: symbol2,
    id: uuidv4(),
  },
  {
    image: symbol3,
    id: uuidv4(),
  },
  {
    image: symbol4,
    id: uuidv4(),
  },
  {
    image: symbol5,
    id: uuidv4(),
  },
  {
    image: symbol6,
    id: uuidv4(),
  },
  {
    image: symbol7,
    id: uuidv4(),
  },
  {
    image: symbol8,
    id: uuidv4(),
  },
  {
    image: symbol9,
    id: uuidv4(),
  },
  {
    image: symbol10,
    id: uuidv4(),
  },
  {
    image: symbol11,
    id: uuidv4(),
  },
  {
    image: symbol12,
    id: uuidv4(),
  },
  {
    image: symbol13,
    id: uuidv4(),
  },
  {
    image: symbol14,
    id: uuidv4(),
  },
  {
    image: symbol15,
    id: uuidv4(),
  },
  {
    image: symbol16,
    id: uuidv4(),
  },
  {
    image: symbol17,
    id: uuidv4(),
  },
  {
    image: symbol18,
    id: uuidv4(),
  },
  {
    image: symbol19,
    id: uuidv4(),
  },
  {
    image: symbol20,
    id: uuidv4(),
  },
];

export { founders, symbols };
