import React, { useState } from "react";
import Screen from "../../components/Screen";
import Hero from "./Hero";
import Mergin from "./Mergin";
import Minting from "./Minting";
import Roadmap from "./Roadmap";
import Team from "./Team";
import Founders from "./Founders";
import Chart from "./Chart";

import Metaverse from "./Metaverse";
import Perks from "./Perks";
import Press from "./Press";
import NftNyc from "./NftNyc";

interface Props {
  showNftNyc?: boolean;
}

export const HomeScreen = ({ showNftNyc }: Props) => {
const [nftNyc, setNftNyc] = useState(showNftNyc)
    
  return (
    <Screen className="home">
      <div className="home-top">
        <Hero />
      </div>
      <div className="home-main">
        <Minting />
        <Mergin />
        <Team />
        <Founders />
        <Roadmap />
        <Perks />
        {/* <Brands /> */}
        <Chart />
        {/* <Events /> */}
        {/* <Mods /> */}
        <Metaverse />
        <Press />
      </div>
      {nftNyc && <NftNyc onClose={() => setNftNyc(false)} />}
    </Screen>
  );
};
