import React from "react";
import SquareCircle from "assets/images/shapes/square-circle.png";
import Arrow from "assets/images/shapes/arrow.png";
import Circles from "assets/images/shapes/circles.png";
import Squares from "assets/images/shapes/squares.png";
import CircleArrow from "assets/images/shapes/circle-arrow.png";
const Fade = require("react-reveal/Fade");

function Shapes() {
  return (
    <div className="home-hero-shapes">
      <Fade top>
        <img src={SquareCircle} alt="" id="shape-1" />
      </Fade>
      <Fade left>
        <img src={Circles} alt="" id="shape-2" />
      </Fade>
      <Fade top>
        <div id="shape-3" className="circle">
          <figure></figure>
        </div>
      </Fade>
      <Fade left>
        <img src={CircleArrow} alt="" id="shape-4" />
      </Fade>
      <Fade bottom>
        <img src={CircleArrow} alt="" id="shape-5" />
      </Fade>
      <Fade left>
        <img src={Circles} alt="" id="shape-6" />
      </Fade>
      <Fade top>
        <img src={Squares} alt="" id="shape-7" />
      </Fade>
      <Fade clear>
        <img src={Arrow} alt="" id="shape-8" />
      </Fade>
      <Fade top>
        <div id="shape-9" className="circle">
          {" "}
          <figure></figure>
        </div>
      </Fade>
      <Fade right>
        <div id="shape-10" className="square">
          <figure></figure>
        </div>
      </Fade>
    </div>
  );
}

export default Shapes;
