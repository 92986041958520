import env from "config/env";
import Web3 from "web3";

export const toWei = (amount: string | number): string => {
  return Web3.utils.toWei(amount.toString(), "ether");
};

export const fromWei = (amount: string | number): string => {
  return Web3.utils.fromWei(amount.toString());
};

export const getUserEthBalance = async (provider?: any, account?: string) => {
  if(!provider){
    return '0'
  }
  const web3 = new Web3(provider);
  if (!account) {
    throw new Error("Please connect");
  }
  const result = await web3.eth.getBalance(account);
  return fromWei(result);
};

export const subscribeToBalanceChange = (account: string, onChange: (value: string) => void, provider?: any) => {
  if(!provider){
    return 
  }
  const web3 = new Web3(provider);

  return web3.eth.subscribe("newBlockHeaders", (err, result) => {

    if (err) {
      console.log(err);
    } else {
      web3.eth.getBalance(account).then((bal) => {
        onChange(fromWei(bal))
      });
    }
  });
};

export const changeWalletNetwork = async (library?: any) => {
  const network = env.network;

  const provider =
    (library && library.provider) ||
    (library && library.currentProvider) ||
    (window as any).ethereum;

  if (!provider || !(window as any).ethereum) {
    throw new Error("Provider missing");
  }
  if (!network) {
    throw new Error("Network missing");
  }

  return provider.request({
    method: "wallet_switchEthereumChain",
    params: [{ chainId: Web3.utils.toHex(network) }],
  });
};

export const toChecksumAddress = (address: string) => {
  return Web3.utils.toChecksumAddress(address);
};

export const getBaseFee = async (provider?: any) => {
  if(!provider){
    return
  }
  const web3 = new Web3(provider);
  const block = await web3.eth.getBlock("pending");

  let result = Math.max(
    Number(Web3.utils.fromWei(block.baseFeePerGas!!.toString(), "gwei")),
    1
  );

  return result * 1.25;
};

export const getEstimateGas = async (provider?: any) => {
  if(!provider){
    return 
  }
  const result = 200000;
  const baseFee = await getBaseFee(provider);
    
  return fromWei(
    Web3.utils.toWei((result * Number(baseFee)).toFixed(6), "Gwei")
  );
};

