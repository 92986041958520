import React from "react";
import ScreenGrid from "../../../components/Screen/ScreenGrid";
import images from "../../../consts/images";
import {OPENSEA_URL, TWITTER_URL} from "../../../consts";
import CustomButton from "components/CustomButton";

const Fade = require("react-reveal/Fade");

function Minting() {

  return (
    <div className="home-minting">
      <Fade left>
        <img
          className="home-minting-shape"
          src={images.common.shape2}
          alt="shape"
        />
      </Fade>
      <ScreenGrid>
        <div className="home-minting-flex flex-container">
          <Fade left>
            <div className="home-minting-left">
              <img
                className="home-minting-left-img"
                src={images.home.mintingPreview}
                alt="nft"
              />
              {/*<img*/}
              {/*  className="home-minting-left-img"*/}
              {/*  src={images.home.minting2}*/}
              {/*  alt="nft"*/}
              {/*/>*/}
              {/*<img*/}
              {/*  className="home-minting-left-img"*/}
              {/*  src={images.home.minting4}*/}
              {/*  alt="nft"*/}
              {/*/>*/}
              {/*<img*/}
              {/*    className="home-minting-left-img"*/}
              {/*    src={images.home.minting3}*/}
              {/*    alt="nft"*/}
              {/*/>*/}
            </div>
          </Fade>
          <Fade bottom>
            <div className="flex-container-content">
              <h2>#Believingisseeing</h2>
              <p className="flex-container-content-text">
                There are 26 letters in the English alphabet and an equal amount
                of symbols that represent each. Owning a symbol unlocks full
                access to our community, upcoming events, exclusive merchandise
                collaborations and dApps. It will also give you an opportunity
                to shape how we all use The Symbols in the future…
                <br/>
                <br/>
                The Symbols Genesis Mint will consist of 2600 generative
                ERC721 NFTs on the Ethereum blockchain. The hand drawn attributes will be combined to
                create random variations on the 26 symbol letters. Some are more rare than others.
                <br/>
                <br/>
                Stay tuned for mint announcements via our <a href={TWITTER_URL}>twitter</a>
              </p>
              {/*<CustomButton onClick={() => history.push(ROUTES.allowlist)}>
              FREE MINT</CustomButton>*/}
              <CustomButton onClick={() => window.open(OPENSEA_URL)}>
                View On OpenSea
              </CustomButton>

            </div>
          </Fade>
        </div>
      </ScreenGrid>
    </div>
  );
}

export default Minting;
