import React from "react";
import { useDispatch } from "react-redux";
import ActionButton from "../../components/transactions/ActionButton";
import useMergeStore from "../../store/hooks/useMergeStore";
import { setShow } from "../../store/reducers/merge";

function MergeAction() {
  const dispatch = useDispatch();
  const { pending } = useMergeStore();

  const onMerge = () => {
    dispatch(setShow(true));
  };
  return (
    <ActionButton
      onAction={onMerge}
      actionPending={pending}
      actionText="Coming soon"
      allowClickWhileLoading
      comingSoon
    />
  );
}

export default MergeAction;
